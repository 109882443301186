import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const blackFridayPage = {
  element: '#black-friday-2020',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default blackFridayPage;