import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const compareFridgesBlock = {
  element: '.comparison-products-block--fridges',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default compareFridgesBlock;