import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
    reducer: state => ({
        comparisons: state.comparisons,
        where_to_buy: state.where_to_buy
    })
});

export default {
    state: {
        comparisons: {
            dishwasher: [],
            hob: [],
            hood: [],
            laundry: [],
            oven: [],
            cooker: [],
            microwave: [],
            refrigeration: [],
            tv: [],
            soundbar: []
        },
        products: {
            loading: false,
            current_products: [],
            active_filters: {
                refrigeration: {
                    colours: [],
                    features: [],
                    sizes: [],
                    types: []
                },
                tv: {
                    features: [],
                    sizes: []
                },
                soundbar: {
                    features: [],
                    sizes: []
                },
                laundry: {
                    colours: [],
                    features: [],
                    sizes: [],
                    types: []
                },
                oven: {
                    colours: [],
                    features: [],
                    types: []
                },
                cooker: {
                    colours: [],
                    features: [],
                    types: []
                },
                microwave: {
                    capacities: [],
                    colours: [],
                    features: [],
                    types: []
                },
                hood: {
                    colours: [],
                    features: [],
                    types: []
                },
                hob: {
                    colours: [],
                    features: [],
                    types: [],
                },
                dishwasher: {
                    colours: [],
                    features: [],
                    types:[],
                },
            },
        },
        where_to_buy: {
            user: {
                lat: 53.8008,
                lng: -1.5491
            },
            product: null
        }
    },
    mutations: {
        UPDATE_PRODUCTS(state, current_products) {
            state.products.current_products = current_products;
        },
        UPDATE_PRODUCT_LOADING_STATUS(state, status) {
            state.products.loading = status;
        },
        UPDATE_DISHWASHER_PRODUCT_FILTER( state, params ) {

            if( params.name == 'dishwasher_colour' ) {
                var filter_index = state.products.active_filters.dishwasher.colours.findIndex(colour => colour.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.dishwasher.colours.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.dishwasher.colours.push(params.value);
                }
            } else if( params.name == 'dishwasher_features' ) {
                var filter_index = state.products.active_filters.dishwasher.features.findIndex(feature => feature.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.dishwasher.features.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.dishwasher.features.push(params.value);
                }
            } else if (params.name == 'dishwasher_type') {
                var filter_index = state.products.active_filters.dishwasher.types.findIndex(type => type.slug === params.value.slug)

                if (filter_index > -1) {
                    state.products.active_filters.dishwasher.types.splice(filter_index, 1);
                } else {
                    state.products.active_filters.dishwasher.types.push(params.value);
                }
            }

        },
        UPDATE_HOB_PRODUCT_FILTER( state, params ) {

            if( params.name == 'hob_colour' ) {
                var filter_index = state.products.active_filters.hob.colours.findIndex(colour => colour.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.hob.colours.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.hob.colours.push(params.value);
                }
            } else if( params.name == 'hob_features' ) {
                var filter_index = state.products.active_filters.hob.features.findIndex(feature => feature.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.hob.features.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.hob.features.push(params.value);
                }
            } else if( params.name == 'hob_type' ) {
                var filter_index = state.products.active_filters.hob.types.findIndex(type => type.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.hob.types.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.hob.types.push(params.value);
                }
            }

        },
        UPDATE_HOOD_PRODUCT_FILTER( state, params ) {

            if( params.name == 'hood_colour' ) {
                var filter_index = state.products.active_filters.hood.colours.findIndex(colour => colour.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.hood.colours.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.hood.colours.push(params.value);
                }
            } else if( params.name == 'hood_features' ) {
                var filter_index = state.products.active_filters.hood.features.findIndex(feature => feature.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.hood.features.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.hood.features.push(params.value);
                }
            } else if( params.name == 'hood_type' ) {
                var filter_index = state.products.active_filters.hood.types.findIndex(type => type.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.hood.types.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.hood.types.push(params.value);
                }
            }

        },
        UPDATE_LAUNDRY_PRODUCT_FILTER( state, params ) {

            if( params.name == 'laundry_colour' ) {
                var filter_index = state.products.active_filters.laundry.colours.findIndex(colour => colour.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.laundry.colours.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.laundry.colours.push(params.value);
                }
            } else if( params.name == 'laundry_features' ) {
                var filter_index = state.products.active_filters.laundry.features.findIndex(feature => feature.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.laundry.features.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.laundry.features.push(params.value);
                }
            } else if( params.name == 'laundry_size' ) {
                var filter_index = state.products.active_filters.laundry.sizes.findIndex(size => size.label === params.value.label)

                if ( filter_index > -1 ) {
                    state.products.active_filters.laundry.sizes.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.laundry.sizes.push(params.value);
                }
            } else if (params.name == 'laundry_type') {

              var filter_index = state.products.active_filters.laundry.types.findIndex(type => type.label === params.value.label)

              if (filter_index > -1) {
                state.products.active_filters.laundry.types.splice(filter_index, 1);
              } else {
                state.products.active_filters.laundry.types.push(params.value);
              }
            }

        },
        UPDATE_OVEN_PRODUCT_FILTER( state, params ) {

            if( params.name == 'oven_colour' ) {
                var filter_index = state.products.active_filters.oven.colours.findIndex(colour => colour.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.oven.colours.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.oven.colours.push(params.value);
                }
            } else if( params.name == 'oven_features' ) {
                var filter_index = state.products.active_filters.oven.features.findIndex(feature => feature.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.oven.features.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.oven.features.push(params.value);
                }
            } else if( params.name == 'oven_type' ) {
                var filter_index = state.products.active_filters.oven.types.findIndex(type => type.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.oven.types.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.oven.type.push(params.value);
                }
            }

        },
        UPDATE_COOKER_PRODUCT_FILTER(state, params) {

            if (params.name == 'cooker_colour') {
                var filter_index = state.products.active_filters.cooker.colours.findIndex(colour => colour.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.cooker.colours.splice(filter_index, 1);
                } else {
                state.products.active_filters.cooker.colours.push(params.value);
                }
            } else if (params.name == 'cooker_features') {
                var filter_index = state.products.active_filters.cooker.features.findIndex(feature => feature.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.cooker.features.splice(filter_index, 1);
                } else {
                state.products.active_filters.cooker.features.push(params.value);
                }
            } else if (params.name == 'cooker_type') {
                var filter_index = state.products.active_filters.cooker.types.findIndex(type => type.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.cooker.types.splice(filter_index, 1);
                } else {
                state.products.active_filters.cooker.type.push(params.value);
                }
            }

        },
        UPDATE_MICROWAVE_PRODUCT_FILTER(state, params) {

            if (params.name == 'microwave_capacity') {
                var filter_index = state.products.active_filters.microwave.capacities.findIndex(capacity => capacity.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.microwave.capacities.splice(filter_index, 1);
                } else {
                state.products.active_filters.microwave.capacities.push(params.value);
                }
            } else if (params.name == 'microwave_colour') {
                var filter_index = state.products.active_filters.microwave.colours.findIndex(colour => colour.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.microwave.colours.splice(filter_index, 1);
                } else {
                state.products.active_filters.microwave.colours.push(params.value);
                }
            } else if (params.name == 'microwave_features') {
                var filter_index = state.products.active_filters.microwave.features.findIndex(feature => feature.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.microwave.features.splice(filter_index, 1);
                } else {
                state.products.active_filters.microwave.features.push(params.value);
                }
            } else if (params.name == 'microwave_type') {
                var filter_index = state.products.active_filters.microwave.types.findIndex(type => type.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.microwave.types.splice(filter_index, 1);
                } else {
                state.products.active_filters.microwave.type.push(params.value);
                }
            }

        },
        UPDATE_REFRIGERATION_PRODUCT_FILTER( state, params ) {

            if( params.name == 'fridge_colour' ) {
                var filter_index = state.products.active_filters.refrigeration.colours.findIndex(colour => colour.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.refrigeration.colours.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.refrigeration.colours.push(params.value);
                }
            } else if( params.name == 'fridge_size' ) {
                var filter_index = state.products.active_filters.refrigeration.sizes.findIndex(size => size.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.refrigeration.sizes.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.refrigeration.sizes.push(params.value);
                }
            } else if( params.name == 'fridge_type' ) {
                var filter_index = state.products.active_filters.refrigeration.types.findIndex(type => type.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.refrigeration.types.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.refrigeration.types.push(params.value);
                }
            } else if( params.name == 'fridge_features' ) {
                var filter_index = state.products.active_filters.refrigeration.features.findIndex(feature => feature.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.refrigeration.features.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.refrigeration.features.push(params.value);
                }
            }

        },
        UPDATE_TV_PRODUCT_FILTER( state, params ) {

            if( params.name == 'tv_features' ) {
                var filter_index = state.products.active_filters.tv.features.findIndex(feature => feature.slug === params.value.slug)

                if ( filter_index > -1 ) {
                    state.products.active_filters.tv.features.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.tv.features.push(params.value);
                }
            } else if( params.name == 'tv_sizes' ) {
                var filter_index = state.products.active_filters.tv.sizes.findIndex(size => size.label === params.value.label)

                if ( filter_index > -1 ) {
                    state.products.active_filters.tv.sizes.splice(filter_index, 1);
                }
                else {
                    state.products.active_filters.tv.sizes.push(params.value);
                }
            }

        },
        UPDATE_SOUNDBAR_PRODUCT_FILTER(state, params) {

            if (params.name == 'soundbar_features') {
                var filter_index = state.products.active_filters.soundbar.features.findIndex(feature => feature.slug === params.value.slug)

                if (filter_index > -1) {
                state.products.active_filters.soundbar.features.splice(filter_index, 1);
                } else {
                state.products.active_filters.soundbar.features.push(params.value);
                }
            }

        },
        UPDATE_DISHWASHER_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.dishwashers.findIndex(dishwasher => dishwasher.ID === product.ID)

            if ( compare_index > -1 ) {
                state.comparisons.dishwashers.splice(compare_index, 1);
            }
            else {
                state.comparisons.dishwashers.push(product);
            }
        },
        UPDATE_HOB_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.hobs.findIndex(hob => hob.ID === product.ID)

            if ( compare_index > -1 ) {
                state.comparisons.hobs.splice(compare_index, 1);
            }
            else {
                state.comparisons.hobs.push(product);
            }
        },
        UPDATE_HOOD_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.hoods.findIndex(hood => hood.ID === product.ID)

            if ( compare_index > -1 ) {
                state.comparisons.hoods.splice(compare_index, 1);
            }
            else {
                state.comparisons.hoods.push(product);
            }
        },
        UPDATE_LAUNDRY_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.laundry.findIndex(laundry => laundry.ID === product.ID)

            if ( compare_index > -1 ) {
                state.comparisons.laundry.splice(compare_index, 1);
            }
            else {
                state.comparisons.laundry.push(product);
            }
        },
        UPDATE_OVEN_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.ovens.findIndex(oven => oven.ID === product.ID)

            if ( compare_index > -1 ) {
                state.comparisons.ovens.splice(compare_index, 1);
            }
            else {
                state.comparisons.ovens.push(product);
            }
        },
        UPDATE_COOKER_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.cookers.findIndex(cooker => cooker.ID === product.ID)

            if (compare_index > -1) {
                state.comparisons.cookers.splice(compare_index, 1);
            } else {
                state.comparisons.cookers.push(product);
            }
        },
        UPDATE_MICROWAVE_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.microwaves.findIndex(microwave => microwave.ID === product.ID)

            if (compare_index > -1) {
                state.comparisons.microwaves.splice(compare_index, 1);
            } else {
                state.comparisons.microwaves.push(product);
            }
        },
        UPDATE_REFRIGERATION_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.refrigeration.findIndex(fridge => fridge.ID === product.ID)

            if ( compare_index > -1 ) {
                state.comparisons.refrigeration.splice(compare_index, 1);
            }
            else {
                state.comparisons.refrigeration.push(product);
            }
        },
        UPDATE_TV_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.tvs.findIndex(tv => tv.ID === product.ID)

            if ( compare_index > -1 ) {
                state.comparisons.tvs.splice(compare_index, 1);
            }
            else {
                state.comparisons.tvs.push(product);
            }
        },
        UPDATE_SOUNDBAR_PRODUCT_COMPARE(state, product) {
            var compare_index = state.comparisons.soundbars.findIndex(soundbar => soundbar.ID === product.ID)

            if (compare_index > -1) {
                state.comparisons.soundbars.splice(compare_index, 1);
            } else {
                state.comparisons.soundbars.push(product);
            }
        },
        UPDATE_WHERE_TO_BUY( state, where_to_buy ) {
            if( where_to_buy.user !== undefined ){
                state.where_to_buy.user = where_to_buy.user;
            }
            if( where_to_buy.product !== undefined ){
                state.where_to_buy.product = where_to_buy.product;
            }

        }
    },
    actions: {
        updateProducts ( state, value ) {
            state.commit( 'UPDATE_PRODUCTS', value );
        },
        updateProductLoadingStatus ( state, value ) {
            state.commit( 'UPDATE_PRODUCT_LOADING_STATUS', value );
        },
        updateProductCompare ( state, params ) {

            if( params.product_type == 'dishwasher' || params.product_type == 'dishwashers' ) {
                state.commit( 'UPDATE_DISHWASHER_PRODUCT_COMPARE', params.product );
            } else if( params.product_type == 'hob' || params.product_type == 'hobs' ) {
                state.commit( 'UPDATE_HOB_PRODUCT_COMPARE', params.product );
            } else if( params.product_type == 'hood' || params.product_type == 'hoods' ) {
                state.commit( 'UPDATE_HOOD_PRODUCT_COMPARE', params.product );
            } else if( params.product_type == 'laundry' ) {
                state.commit( 'UPDATE_LAUNDRY_PRODUCT_COMPARE', params.product );
            } else if( params.product_type == 'oven' || params.product_type == 'ovens' ) {
                state.commit( 'UPDATE_OVEN_PRODUCT_COMPARE', params.product );
            } else if (params.product_type == 'cooker' || params.product_type == 'cookers') {
                state.commit('UPDATE_COOKER_PRODUCT_COMPARE', params.product);
            } else if (params.product_type == 'microwave' || params.product_type == 'microwaves') {
                state.commit('UPDATE_MICROWAVE_PRODUCT_COMPARE', params.product);
            } else if (params.product_type == 'refrigeration') {
                state.commit( 'UPDATE_REFRIGERATION_PRODUCT_COMPARE', params.product );
            } else if( params.product_type == 'tv' || params.product_type == 'tvs' ) {
                state.commit( 'UPDATE_TV_PRODUCT_COMPARE', params.product );
            } else if (params.product_type == 'soundbar' || params.product_type == 'soundbars') {
                state.commit('UPDATE_SOUNDBAR_PRODUCT_COMPARE', params.product);
            }

        },
        updateProductFilter ( state, params ) {

            if( params.product_type == 'dishwasher' ) {
                state.commit( 'UPDATE_DISHWASHER_PRODUCT_FILTER', params );
            } else if( params.product_type == 'hob' ) {
                state.commit( 'UPDATE_HOB_PRODUCT_FILTER', params );
            } else if( params.product_type == 'hood' ) {
                state.commit( 'UPDATE_HOOD_PRODUCT_FILTER', params );
            } else if( params.product_type == 'laundry' ) {
                state.commit( 'UPDATE_LAUNDRY_PRODUCT_FILTER', params );
            } else if( params.product_type == 'oven' ) {
                state.commit( 'UPDATE_OVEN_PRODUCT_FILTER', params );
            } else if (params.product_type == 'cooker') {
                state.commit('UPDATE_COOKER_PRODUCT_FILTER', params);
            } else if (params.product_type == 'microwave') {
                state.commit('UPDATE_MICROWAVE_PRODUCT_FILTER', params);
            } else if (params.product_type == 'refrigeration') {
                state.commit( 'UPDATE_REFRIGERATION_PRODUCT_FILTER', params );
            } else if( params.product_type == 'tv' ) {
                state.commit( 'UPDATE_TV_PRODUCT_FILTER', params );
            } else if (params.product_type == 'soundbar') {
                state.commit('UPDATE_SOUNDBAR_PRODUCT_FILTER', params);
            }

        },
        updateWheretoBuy ( state, where_to_buy ) {
            state.commit( 'UPDATE_WHERE_TO_BUY', {
                user: where_to_buy.user,
                product: where_to_buy.product
            });
        }
    },
    plugins: [vuexLocalStorage.plugin]
}

