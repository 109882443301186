<template>
    <div class="filter">
		<div class="filter-underlay"></div>
		<div class="filter-results">
			<p v-if="products.loading == false" class="txt-greyLL">{{ product_count }}</p>
			<p v-else class="txt-greyLL">{{ $t('product.loading') }}</p>
		</div>
		<div v-if="products.active_filters.tv.features.length > 0 || products.active_filters.tv.sizes.length > 0" class="filter-active-filters">
			<a v-for="(size, size_index) in products.active_filters.tv.sizes" :key="'size_index-' + size_index" class="product-filter" @click="toggleFilter( 'tv_sizes', size )">
				<span>{{ cleanText(size.label) }}</span>
			</a>
			<a v-for="(feature, feature_index) in products.active_filters.tv.features" :key="'feature_index-' + feature_index" class="product-filter" @click="toggleFilter( 'tv_features', feature )">
				<span>{{ cleanText(feature.name) }}</span>
			</a>
		</div>
		<div class="filter-button">
			<p>{{ $t('product.filter') }}</p>
			<div class="filtercta">
				<simple-svg :filepath="theme_path + 'images/svg/filters.svg'" :width="'19px'" :height="'20px'" />
			</div>
		</div>
		<div class="filter-wrap">
			<a class="update-filter">{{ $t('product.update') }}</a>
			<div class="closefilter">
				<simple-svg :filepath="theme_path + 'images/svg/close.svg'" :width="'11.5px'" :height="'11.5px'"/>
			</div>			

			<div class="filter-inner filter-tv">
				<div v-if="sizes.length > 0" class="filt filt-size">
					<p class="title">{{ $t('product.size') }}</p>
					<ul class="sizes">
						<template v-for="(size_group, index) in size_groups">
							<li v-if="size_group.values.join(',') != ''" :key="index">
								<a class="product-filter-link" :data-values="size_group.values.join(',')" :class="{ active : activeFilter( 'tv_sizes', size_group ) }" :id="getTVSizeURL(size_group)" data-type="tv_sizes" @click="toggleFilter( 'tv_sizes', size_group )">
									<span v-html="size_group.label + ' &rsaquo;'"></span>
								</a>
							</li>
						</template>
					</ul>
				</div>

				<div v-if="features.length > 0" class="filt filt-feat">
					<p class="title">{{ $t('product.features') }}</p>
					<ul class="features">
						<li v-for="(current_feature, index) in features" :key="index">
							<a v-if="current_feature.feature !== null" class="product-filter-link" :data-values="current_feature.feature.slug" :class="{ active : activeFilter( 'tv_features', current_feature.feature ) }" data-type="tv_features" @click="toggleFilter( 'tv_features', current_feature.feature )">
								<span v-html="current_feature.feature.name + ' &rsaquo;'"></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props: [ 'features', 'sizes', 'size_groups' ],
        computed: {
            ...mapState(
				['products'],
			)
		},
		methods: {
			getTVSizeURL: function(size_group) {
				if( size_group.min == size_group.max ) return 'sizes-' + size_group.min;
				else return 'sizes-' + size_group.min + '-' + size_group.max;
			}
		},
        data() {
            return {
            }
        }
    }
</script>