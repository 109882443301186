import FeaturedProducts from '../../components/FlexibleContent/FeaturedProducts/Index';
import WhereToBuy from '../../components/WhereToBuy/Index';

const featuredProductsPage = {
  element: '#featured-products',
  components: {
    FeaturedProducts,
    WhereToBuy
  }
}

export default featuredProductsPage;