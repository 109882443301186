import MicrowaveProductFilter from '../../components/Products/Microwave/Filter';
import MicrowaveProductList from '../../components/Products/Microwave/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveMicrowave = {
  element: '#archive-microwave',
  components: {
    MicrowaveProductFilter,
    MicrowaveProductList,
    WhereToBuy
  }
}

export default archiveMicrowave;