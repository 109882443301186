<template>
    <div class="online-retailers">
        <template v-for="(retailer, index) in retailers">
            <div v-if="retailer.Availability == 'Available'" class="online-retailer" :key="'online-' + index">
                <div class="online-retailer__meta">
                    <img :src="retailer.LogoUrl">
                    <div class="online-retailer__notes">
                        <p class="online-retailer__stock in-stock">{{ $t('where_to_buy.in_stock') }}</p>
                    </div>
                </div>
                <a class="button button--green-full" :key="index" :href="getProductLink(retailer.ProductLink)" :title="$t('where_to_buy.stock_availability') + ' ' + retailer.DisplayName" :data-text="$t('product.buy_now')" target="_blank">
                    <span>{{ $t('product.buy_now') }}</span>
                </a>
                <p v-if="hasPromotion(retailer.Promotions)" class="online-retailer__promotion" v-html="hasPromotion(retailer.Promotions)"></p>
            </div>
        </template>
    </div> 
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import moment from 'moment';

    export default {
        props: ['retailers'],
        methods: {
            getProductLink: function( product_link ){
                let uri = window.location.search.substring(1);

                if( Cookies.get('utm_campaign') !== null && Cookies.get('utm_campaign') !== "") {
                    product_link = product_link + '&tag=' + Cookies.get('utm_campaign');
                }

                return product_link;
                
            },
            hasPromotion: function( promotion ){

                if( promotion !== undefined && promotion[0] !== undefined && promotion[0].PromotionMessage !== undefined ) {

                    var startDate   = moment(promotion[0].StartDateTimeUtc).format("YYYY-MM-DD"),
                        endDate     = moment(promotion[0].EndDateTimeUtc).format("YYYY-MM-DD"),
                        currentDate = moment().format("YYYY-MM-DD");

                    if( moment(currentDate).isBetween(startDate, endDate) ) {
                        return promotion[0].PromotionMessage;
                    } else {
                        return false;
                    }

                }

                return false;

            },
        }
    }
</script>