import HoodProductFilter from '../../components/Products/Hood/Filter';
import HoodProductList from '../../components/Products/Hood/List';
import WhereToBuy from '../../components/WhereToBuy/Index';


const archiveHood = {
  element: '#archive-hood',
  components: {
    HoodProductFilter,
    HoodProductList,
    WhereToBuy
  }
}

export default archiveHood;