<template>
    <div id="where-to-buy">
        <div class="pullout-overlay pullout-overlay-wtb" :class="{  }">
            <div class="underlay" @click="closeWTB()">
                <div class="lds-ripple"><div></div><div></div></div>
            </div>
            <section class="product-wtb" :class="{ 'many-varients' : ( where_to_buy.product !== null && where_to_buy.product.eans.length > 4 ), 'product-wtb--simple' : where_to_buy.product && where_to_buy.product.wtb_mode === 'simple' }">
                <div class="inner" v-if="where_to_buy.product !== null">
                    <div class="wtb-close" @click="closeWTB()">
                        <simple-svg :filepath="'/app/themes/hisense/assets/dist/images/svg/close.svg'" :width="'11.5px'" :height="'11.5px'"/>
                    </div>
                    <div class="meta">
                        <div class="meta__image" v-if="where_to_buy.product.content.image" v-html="where_to_buy.product.content.image">
                        </div>
                        <div class="meta__titles">
                            <p class="title">Hisense {{ where_to_buy.product.content.name }} <span v-if="where_to_buy.product.content.category">/ <a>{{ where_to_buy.product.content.category }}</a></span></p>
                            <p class="tagline" v-html="where_to_buy.product.content.tagline"></p>
                        </div>
                        <categories></categories>
                    </div>

                    <div class="product-wtb__results-container" v-if="where_to_buy.product && where_to_buy.product.wtb_mode === 'simple'">
                        <div class="product-wtb__results" :class="{ active : index == 0 }" v-for="(variant, index) in where_to_buy.product.eans" :key="'wtb_result-'+index">
                            <div class="product-wtb__results--online active">
                                <div class="results">
                                    <p class="no-results" v-if="variant.retailers.online == null || variant.retailers.online.length == 0">{{ $t('where_to_buy.no_results') }}</p>
                                    <online-retailers-simple v-else :retailers="variant.retailers.online" :product="where_to_buy.product.content"></online-retailers-simple>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-wtb__results-container" v-else>
                        <div class="product-wtb__results" :class="{ active : index == 0 }" v-for="(variant, index) in where_to_buy.product.eans" :key="'wtb_result-'+index">
                            <div class="product-wtb__tabs">
                                <div class="product-wtb__tab product-wtb__tab--online active" @click="toggleWTBGroup('online')">
                                    <p>{{ $t('where_to_buy.online') }}</p>
                                </div>

                                <div class="product-wtb__tab product-wtb__tab--local" @click="toggleWTBGroup('local')">
                                    <p>{{ $t('where_to_buy.in_store') }}</p>
                                </div>
                            </div>
                            <div class="product-wtb__results--online active">
                                <div class="title-bar">
                                    <p>{{ $t('where_to_buy.online') }}</p>
                                </div>
                                <div class="results">
                                    <online-retailers v-if="showOnlineRetailers(variant.retailers.online)" :retailers="variant.retailers.online"></online-retailers>
                                    <p class="no-results" v-else>{{ $t('where_to_buy.no_results') }}</p>
                                </div>
                            </div>
                            <div class="product-wtb__results--local">
                                <div class="title-bar">
                                    <p>{{ $t('where_to_buy.in_store') }}</p>
                                    <div class="local-search-form__container">
                                        <a class="map-toggle" @click="toggleLocalResultsGroup('map')">
                                            <simple-svg :filepath="theme_path + 'images/svg/wtb-map-icon.svg'" :width="'40px'" :height="'30px'" />
                                        </a>
                                        <div class="local-search-form">
                                            <gmap-autocomplete @place_changed="setPlace"></gmap-autocomplete>
                                        </div>
                                        <a class="list-toggle" @click="toggleLocalResultsGroup('list')">
                                            <simple-svg :filepath="theme_path + 'images/svg/wtb-list-icon.svg'" :width="'40px'" :height="'30px'" />
                                        </a>
                                    </div>
                                </div>
                                <div class="results">
                                    <local-retailers :retailers="variant.retailers.local" :user="where_to_buy.user" :loading="loading"></local-retailers>
                                    <p class="no-results" :class="{ 'is-loading' : loading }" v-if="variant.retailers.local == null || variant.retailers.local.length == 0">{{ $t('where_to_buy.no_results') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
   </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { TimelineMax, Power3 } from "gsap";

    import Categories from './Categories';

    import LocalRetailers from './LocalRetailers';
    import OnlineRetailers from './OnlineRetailers';
    import OnlineRetailersSimple from './OnlineRetailersSimple';

    export default {
        components: {
            LocalRetailers,
            OnlineRetailers,
            OnlineRetailersSimple,
            Categories
        },
        computed: {
            ...mapState(
                ['where_to_buy'],
            )
        },
        methods: {
            closeWTB: function( ){

                var pulloutTimelineWtb = new TimelineMax({ paused: true, reversed: true });

                    pulloutTimelineWtb
                        .from( jQuery('.product-wtb'), 0, { css: { right: "0" }, ease: Power3.easeInOut })
                        .to( jQuery('.product-wtb'), 0.3, { css: { right: "-110%" }, ease: Power3.easeInOut }, 0.3 )
                        .to( jQuery('.pullout-overlay-wtb'), 0.3, { opacity: 0, ease: Power3.easeInOut,
                            onComplete: function() { jQuery('.pullout-overlay-wtb').hide(); } }).play();

                jQuery('html').removeClass('noscroll');
                jQuery('.pullout-overlay-wtb').removeClass('active');

                Event.$emit('reset_wtb');
            },
            setPlace(place) {
                let vm = this,
                    store = this.$store,
                    product_id = this.where_to_buy.product.content.ID;
                
                var api_url = '/wp-json/hisense/v1/where-to-buy/' + product_id;

                vm.loading = true;

                jQuery('.local-retailers__results, .local-retailers__list').scrollTop(0);

                if (!place) return

                if( place.geometry !== undefined && place.geometry.location.lat() !== null ) {
                    store.dispatch( 'updateWheretoBuy', {
                        user: {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        }
                    } );

                    api_url = api_url + '?lat=' + place.geometry.location.lat() + '&lng=' + place.geometry.location.lng();

                    jQuery.ajax({
                        url: api_url,
                        method: 'GET',
                        success: function( response, textStatus, xhr ){
                            store.dispatch( 'updateWheretoBuy', {
                                product: response
                            } );

                            vm.loading = false;
                        }
                    });
                }
            },
            showOnlineRetailers: function(retailers){

                if( retailers ) {
                    var filteredRetailers = retailers.filter(function(item){
                        if( item.Availability !== undefined && item.Availability.includes('NotAvailable') ) {
                            return item;
                        }
                    });

                    if( filteredRetailers.length != retailers.length ) {
                        return true;
                    }
                }

                return false;
            },
            toggleWTBGroup: function( group ) {
                jQuery('.product-wtb__tab').removeClass('active');
                jQuery('.product-wtb__tab--' + group).addClass('active');

                jQuery('.product-wtb__results--online, .product-wtb__results--local').removeClass('active');
                jQuery('.product-wtb__results--' + group).addClass('active');
            },
            toggleLocalResultsGroup: function( group ) {
                if( group == 'list' ) {
                    jQuery('.local-retailers__results').removeClass('map-only');
                    jQuery('.local-retailers__results').toggleClass('list-only');
                    jQuery('.product-wtb__results--local .map-toggle').removeClass('active');
                    jQuery('.product-wtb__results--local .list-toggle').toggleClass('active');
                }
                if( group == 'map' ) {
                    jQuery('.local-retailers__results').removeClass('list-only');
                    jQuery('.local-retailers__results').toggleClass('map-only');
                    jQuery('.product-wtb__results--local .list-toggle').removeClass('active');
                    jQuery('.product-wtb__results--local .map-toggle').toggleClass('active');
                }
            }
        },
        data() {
            return {
                loading : false,
                currentPlace: null
            }
        }
    }
</script>