import RefrigerationProductFilter from '../../components/Products/Refrigeration/Filter';
import RefrigerationProductList from '../../components/Products/Refrigeration/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveRefrigeration = {
    element: '#archive-refrigeration',
    components: {
        RefrigerationProductFilter,
        RefrigerationProductList,
        WhereToBuy
    }
}

export default archiveRefrigeration;