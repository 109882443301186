require("@babel/polyfill");
require("./components/cursor-takeover.js");

// import { gsap, TweenMax, TimelineMax, Expo, Power3 } from "gsap";

import Vue from 'vue';
import Vuex from 'vuex';
import VueMq from 'vue-mq';
import VueI18n from 'vue-i18n';
import VueSimpleSVG from 'vue-simple-svg'
import VueMatchHeights from 'vue-match-heights';
import * as VueGoogleMaps from 'vue2-google-maps'

var Promise = require('es6-promise').Promise;

//Helpers
import './vue/helpers/mixins';
import store from './vue/helpers/store';
import translations from './vue/helpers/i18n';
import location from './vue/helpers/location';

// import { mapState, mapMutations } from 'vuex';

Vue.use(Vuex);

Vue.use(VueMq, {
    breakpoints: {
        mobile      : 0,
        tablet      : 980,
        desktop     : 100000
    }
});

Vue.use(VueMatchHeights, {
    disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});

Vue.use(VueSimpleSVG);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA0EY2HU4u1cudqfljaE71VATgMxn_lozg',
    libraries: 'places',
  },
  installComponents: true
});

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: location,
    messages: translations,
});

window.Event = new Vue();

import Schema from './vue/schema/';

Schema.forEach( entry => {
    if (jQuery(entry.element).length ){
        const instance = new Vue({
            i18n,
            el: entry.element,
            components: entry.components,
            store: new Vuex.Store(store)
        });
    }
});