// Post type archives
import archiveRefrigeration from './archives/refrigeration';
import archiveTv from './archives/tv';
import archiveSoundbar from './archives/soundbar';
import archiveLaundry from './archives/laundry';
import archiveOven from './archives/oven';
import archiveMicrowave from './archives/microwave';
import archiveCooker from './archives/cooker';
import archiveHood from './archives/hood';
import archiveHob from './archives/hob';
import archiveDishwasher from './archives/dishwasher';

// Pages
import productComparisonPage from './pages/productComparison';
import featuredProductsPage from './pages/featuredProducts';
import searchResultsPage from './pages/searchResults';
import productListerPage from './pages/productLister';
import singleProductPage from './pages/singleProduct';
import blackFridayPage from './pages/blackFriday2020';

// Blocks
import compareDishwashersBlock from './blocks/compareDishwashers';
import compareFridgesBlock from './blocks/compareFridges';
import compareHobsBlock from './blocks/compareHobs';
import compareHoodsBlock from './blocks/compareHoods';
import compareLaundryBlock from './blocks/compareLaundry';
import compareOvensBlock from './blocks/compareOvens';
import compareMicrowavesBlock from './blocks/compareMicrowaves';
import compareCookersBlock from './blocks/compareCookers';
import compareSoundbarsBlock from './blocks/compareSoundbars';
import compareTvsBlock from './blocks/compareTvs';
import ctaBlock from './blocks/ctaBlock';

const Schema = [
    archiveRefrigeration,
    archiveTv,
    archiveSoundbar,
    archiveLaundry,
    archiveOven,
    archiveMicrowave,
    archiveCooker,
    archiveHood,
    archiveHob,
    archiveDishwasher,
    productComparisonPage,
    featuredProductsPage,
    searchResultsPage,
    productListerPage,
    singleProductPage,
    blackFridayPage,
    compareDishwashersBlock,
    compareFridgesBlock,
    compareHobsBlock,
    compareHoodsBlock,
    compareLaundryBlock,
    compareOvensBlock,
    compareMicrowavesBlock,
    compareCookersBlock,
    compareTvsBlock,
    compareSoundbarsBlock,
    ctaBlock
]

export default Schema;