var location = 'en';
var host = window.location.host;

if( host.includes('de.') ) {
    location = 'de';
} else if( host.includes('dk.') ) { 
    location = 'dk';
} else if( host.includes('fin.') || host.includes('fi.') ) {
    location = 'fi';
} else if( host.includes('no.') ) {
    location = 'no';
} else if( host.includes('se.') ) {
    location = 'se';
}

export default location;