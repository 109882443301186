<template>
    <div>
        <comparison-bar v-if="comparisons.hob.length > 0" :product_type="'hobs'" :compare_products="comparisons.hob"></comparison-bar>
        <div class="wrapper">
            <loading-screen v-if="products.loading == true"></loading-screen>
            <product-list-item v-else v-for="product in products.current_products" :product="product" :key="product.id"></product-list-item>
            <no-products v-if="products.current_products == undefined && products.loading == false"></no-products>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import LoadingScreen from './../LoadingScreen';
    import ComparisonBar from './../ComparisonBar';
    import ProductListItem from './../ListItem';
    import NoProducts from './../NoProducts';
    import {updateViewItemListDataLayer} from '../../../helpers/datalayer';

    var urlParts = window.location.pathname.split('/');
    if( urlParts[0] == '') urlParts.shift();
    if( urlParts[urlParts.length - 1] == '') urlParts.pop();

    export default {
        components: {
            LoadingScreen,
            ComparisonBar,
            ProductListItem,
            NoProducts
        },
        computed: {
            ...mapState(
				['products', 'comparisons'],
            )
        },
        methods: {
            setDefaultFilters( filters ) {
                let vm = this;

                if( filters.hob_colour != undefined ) {
                    filters.hob_colour.forEach(function(colour) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'hob_colour', 'product_type' : 'hob', 'value' : colour } );
                    });
                }

                if( filters.hob_type != undefined ) {
                    filters.hob_type.forEach(function(type) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'hob_type', 'product_type' : 'hob', 'value' : type } );
                    });
                }
                
                if( filters.hob_features != undefined ) {
                    filters.hob_features.forEach(function(feature) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'hob_features', 'product_type' : 'hob', 'value' : feature } );
                    });
                }

            },
            setFilterString() {
                let vm = this;

                var append = '?',
                    hob_colours = [],
                    hob_features = [],
                    hob_type = [];
                
                if( vm.$store.state.products.active_filters.hob.colours.length > 0 ){

                    vm.$store.state.products.active_filters.hob.colours.forEach(function(colour) {
                        hob_colours.push(colour.slug);
                    });

                    hob_colours = hob_colours.join(',');

                    append = append + '&hob_colour=' + hob_colours;

                } 

                if( vm.$store.state.products.active_filters.hob.features.length > 0 ){

                    vm.$store.state.products.active_filters.hob.features.forEach(function(feature) {
                        hob_features.push(feature.slug);
                    });

                    hob_features = hob_features.join('+');

                    append = append + '&hob_features=' + hob_features;

                } 
                
                if( vm.$store.state.products.active_filters.hob.types.length > 0 ){

                    vm.$store.state.products.active_filters.hob.types.forEach(function(type) {
                        hob_type.push(type.slug);
                    });

                    hob_type = hob_type.join(',');

                    append = append + '&hob_type=' + hob_type;

                }
                
                append = append.replace("?&", "?");
                if( append == '?' ) append = '';

                return append;
            },
            resetProducts() {
                let vm = this; 

                var append = '?',
                    hob_colours = [],
                    hob_features = [],
                    hob_type = [];

                vm.$store.dispatch('updateProductLoadingStatus', true );

                hob_colours = vm.getUrlParameter('hob_colour');
                hob_features = vm.getUrlParameter('hob_features');
                hob_type = vm.getUrlParameter('hob_type');

                if(urlParts[2] == 'features' && urlParts[3] !== ''){
                    hob_features = urlParts[3];
                } else if(urlParts[2] == 'type' && urlParts[3] !== ''){
                    hob_type = urlParts[3];
                }

                if( hob_colours != undefined ) {
                    append = append + '&hob_colour=' + hob_colours;
                }
                if( hob_features != undefined ) {
                    append = append + '&hob_features=' + hob_features;
                }
                if( hob_type != undefined ) {
                    append = append + '&hob_type=' + hob_type;
                }

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-hobs/' + append,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.setDefaultFilters( response.filters );
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            }
        },
        watch: {
            '$store.state.products.current_products': {
                handler: function(currentProducts) {
                    updateViewItemListDataLayer(currentProducts, 'Hob List');
                },
                deep: true
            }
        },
        mounted(){
            let vm = this;

            vm.resetProducts();

            Event.$on( 'update_products', ($event) => {

                vm.$store.dispatch('updateProductLoadingStatus', true );

                var queryURL = vm.setFilterString();
                var pushURL = '/' + urlParts[0] + '/' + urlParts[1] + '/' + queryURL;

                window.history.pushState('', '', pushURL);

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-hobs/' + queryURL,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            });

        },
        data() {
            return {
            }
        }
    }

</script>