import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const compareCookersBlock = {
  element: '.comparison-products-block--cookers',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}
export default compareCookersBlock;