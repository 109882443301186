<template>
    <div class="comparison-page">
        <div class="page-header page-header-product-compare">
            <div class="top">
                <h1 class="headline fadeInUp">{{ $t('compare.title') }}</h1>
            </div>
        </div>
        <div class="comparison-table-wrapper">
            <div class="comparison-table">
                <template v-for="(ct_group, ct_group_key) in comparison_products.groups">
                    <div class="ct-group sticky-rows" v-if="ct_group.label == 'Product' || ct_group.label == 'Model'" :key="'ct_group_stickykey-' + ct_group_key">
                        <div class="ct-spec">
                            <div class="ct-spec product-details">
                                <div class="ct-spec-label product">
                                </div>
                                <template v-for="index in 4">
                                    <div v-if="comparison_products.product[index] !== undefined" class="ct-spec-product" :key="'value_key-' + index">
                                        <div class="product-image" v-html="comparison_products.product[index].thumbnail"></div>
                                        <p class="product-title">{{ comparison_products.product[index].name }}</p>
                                    </div>
                                    <div v-else class="ct-spec-product empty" :key="'value_key-' + index">
                                        &ndash;
                                    </div>
                                </template>
                            </div>
                            <div class="ct-spec-wtb">
                                <div class="ct-spec-label">&nbsp;</div>
                                <div class="ct-spec-product" v-for="index in 4" :key="'value_key-' + index">
                                    <template v-if="comparison_products.product[index] !== undefined">
                                        <a :data-text="$t('product.buy_now')" class="button button--green-full" @click="fetchProductWTB(comparison_products.product[index].ID)"><span>{{ $t('product.buy_now') }}</span></a>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="ct-group" v-for="(ct_group, ct_group_key) in comparison_products.groups" :key="'ct_group_key-' + ct_group_key">
                    <template v-if="ct_group.label != 'Product' && ct_group.label != 'Model'">
                        <div class="ct-group-label">
                            {{ ct_group.label }}
                        </div>
                    </template>
                    <div class="ct-spec" v-for="(spec, specs_key) in ct_group.specs" :key="'specs_key-' + specs_key">
                        <div class="ct-spec-label">
                            {{ spec.label }}
                        </div>
                        <div class="ct-spec-product" v-for="index in 4" :key="'value_key-' + index">
                            <span v-if="spec.values[index] !== undefined" v-html="spec.values[index]"></span>
                            <span v-else>&ndash;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-products">
            <p>{{ $t('compare.looking') }}.</p>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props: [
            'product_type'
        ],
        computed: {
            ...mapState(
				['comparisons'],
            )
        },
        mounted(){
            let vm = this;

            var comparison_ids = [];

            if( vm.product_type == 'dishwashers' ) {
                vm.products = vm.comparisons.dishwasher;
            } else if( vm.product_type == 'hobs' ) {
                vm.products = vm.comparisons.hob;
            } else if( vm.product_type == 'hoods' ) {
                vm.products = vm.comparisons.hood;
            } else if( vm.product_type == 'laundry' ) {
                vm.products = vm.comparisons.laundry;
            } else if( vm.product_type == 'ovens' ) {
                vm.products = vm.comparisons.oven;
            } else if( vm.product_type == 'cookers' ) {
                vm.products = vm.comparisons.oven;
            } else if( vm.product_type == 'microwaves' ) {
                vm.products = vm.comparisons.microwave;
            } else if( vm.product_type == 'refrigeration' ) {
                vm.products = vm.comparisons.refrigeration;
            } else if( vm.product_type == 'tvs' ) {
                vm.products = vm.comparisons.tv;
            } else if( vm.product_type == 'soundbars' ) {
                vm.products = vm.comparisons.soundbar;
            }

            vm.products.forEach(function(product) {
                comparison_ids.push(product.ID);
            });

            jQuery.ajax({
                url: '/wp-json/hisense/v1/compare-products/',
                method: 'GET',
                data: { 'products' :  comparison_ids },
                success: function( response, textStatus, xhr ){
                    vm.comparison_products = response;

                    if( vm.comparison_products.product !== undefined ) {
                        if( Object.keys(vm.comparison_products.product).length == 0 ){
                            jQuery('.comparison-table').removeClass('active');
                            jQuery('.no-products').show();
                            jQuery('.no-products p').html($t('compare.no_products_to_compare'));
                        } else {
                            jQuery('.comparison-table').addClass('active');
                            jQuery('.no-products').hide();
                        }
                    } else {
                        jQuery('.comparison-table').removeClass('active');
                        jQuery('.no-products').show();
                        jQuery('.no-products p').html($t('compare.no_products_to_compare'));
                    }

                }
            });

            jQuery(window).scroll(function(){
                if( jQuery('.sticky-rows').length ) {
                    if( jQuery('.sticky-rows').offset().top > jQuery('.comparison-table').offset().top ){
                        jQuery('.sticky-rows').addClass('sticky-scrolly');
                    } else {
                        jQuery('.sticky-rows').removeClass('sticky-scrolly');
                    }
                }
            });

        },
        methods: {
            fetchProductWTB: function( product_id ){
                let vm = this;
                let store = this.$store;

                vm.wtb_loading = true;
                vm.wtb_button_text = vm.$t('search.searching');

                jQuery('html').addClass('noscroll');

                var pulloutOverlayTimeline = new TimelineMax({ paused: true, reversed: true });

                pulloutOverlayTimeline
                    .set( jQuery('.pullout-overlay-wtb'), { css: { className: '+=active' } } )
                    .set( jQuery('.pullout-overlay-wtb'), { css: { className: '+=loading' } } )
                    .to( jQuery('.pullout-overlay-wtb'), 0.3, { autoAlpha: 1, ease: Power3.easeInOut } ).play();

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/where-to-buy/' + product_id,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        store.dispatch( 'updateWheretoBuy', {
                            product: response
                        } );

                        vm.wtb_loading = false;
                        vm.wtb_button_text = vm.$t('product.buy_now');

                        jQuery('.pullout-overlay-wtb').removeClass('loading');

                        var pulloutWTBTimeline = new TimelineMax({ paused: true, reversed: true });

                        pulloutWTBTimeline
                            .from( jQuery('.product-wtb'), 0.3, { css: { right: "-110%" }, ease: Power3.easeInOut })
                            .to( jQuery('.product-wtb'), 0.3, { css: { right: "0" }, ease: Power3.easeInOut }, 0.3 ).play();
                    }
                });

            }
        },
        data() {
            return {
                comparison_products: {
                    groups: {}
                },
                products: []
            }
        }
    }
</script>