import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const compareTvsBlock = {
  element: '.comparison-products-block--tvs',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default compareTvsBlock;