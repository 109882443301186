<template>
    <div class="no-results">
        <div class="resultswrap">
            <p class="headline" v-if="no_search_results_boxes.length > 0">{{ $t('search.no_results_explore') }}</p>
            <p class="headline" v-else>{{ $t('search.no_results') }}</p>
            <p class="supporting" v-if="no_search_results_boxes.length > 0">{{ $t('search.explore') }}</p>
        </div>
        <div class="explore-products" v-if="no_search_results_boxes.length > 0">
            <a class="explore-box" :href="box.link" v-for="(box, box_index) in no_search_results_boxes" :key="box_index" :style="'background-image: url('+ box.image.url +');'">
                <p v-html="box.title"></p>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    props: [ 'no_search_results_boxes' ],
    data () {
		return { 
            
        }
    }
}
</script>