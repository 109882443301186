<template>
    <div class="col-12 swiper-container">
        <div class="swiper-wrapper">
            <featured-product-item v-for="product in products.current_products" :product="product" :key="product.id"></featured-product-item>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import FeaturedProductItem from './Single';

    export default {
        props: [ 'product_type' ],
        components: {
            FeaturedProductItem
        },
        computed: {
            ...mapState(
				['products'],
            )
        },
        mounted(){
            let vm = this;
            vm.resetProducts();
        },
        methods: {
            resetProducts() {
                let vm = this; 

                // Slider - Featured products
                var relatedSwiper = new Swiper('#featured-products .swiper-container', {
                    slidesPerView: 2,
                    speed: 400,
                    spaceBetween: 100,
                    navigation: {
                        nextEl: '.swp-next',
                        prevEl: '.swp-prev',
                    },
                    breakpoints: {
                        640: {
                            slidesPerView: 1
                        }
                    }
                });

                vm.$store.dispatch('updateProductLoadingStatus', true );

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/featured-products/?product_type=' + vm.product_type,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );

                        setTimeout(function () {
                            relatedSwiper.update();  
                        }, 500);

                    }
                });

            }
        },
        data() {
            return { }
        }
    }
</script>