import OvenProductFilter from '../../components/Products/Oven/Filter';
import OvenProductList from '../../components/Products/Oven/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveOven = {
  element: '#archive-oven',
  components: {
    OvenProductFilter,
    OvenProductList,
    WhereToBuy
  }
}

export default archiveOven;