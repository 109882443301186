<template>
    <div>
        <div v-for="product_type in products" :product_type="product_type" :key="product_type.name" class="new-products" :class="product_type.name">
            <div class="found-count" v-if="show_title && product_type.products !== undefined">
                <p>{{ product_type.name }} <span>({{ product_type.products.length }})</span></p>
            </div>
            <div class="wrapper">
                <single-product v-for="product in product_type.products" :product="product" :key="product.id"></single-product>
            </div>
        </div>
    </div>
</template>
<script>
    import SingleProduct from './ListItem';

    export default {
        props: [ 'products', 'show_title' ],
        components: {
            SingleProduct
        },
        created(){
            setTimeout(function(){
                document.dispatchEvent(new CustomEvent('matchheight', {}));
            }, 1000);
        },
        data() {
            return { }
        }
    }
</script>