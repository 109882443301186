import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const compareLaundryBlock = {
  element: '.comparison-products-block--laundry',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default compareLaundryBlock;