import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const compareSoundbarsBlock = {
  element: '.comparison-products-block--soundbars',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default compareSoundbarsBlock;