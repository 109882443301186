import TvProductFilter from '../../components/Products/TV/Filter';
import TvProductList from '../../components/Products/TV/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveTv = {
    element: '#archive-tv',
    components: {
        TvProductFilter,
        TvProductList,
        WhereToBuy
    }
}

export default archiveTv;