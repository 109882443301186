import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const compareHobsBlock = {
  element: '.comparison-products-block--hobs',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default compareHobsBlock;