<template>
    <div class="search-result search-result-page pli">
		<h2><a :href="post.url" v-html="post.title"></a></h2>
		<p v-html="post.excerpt"></p>
	</div>
</template>
<script>
    export default {
        props: [ 'post' ],
        data() {
            return { }
        }
    }
</script>