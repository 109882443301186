const translations = {
    dk: {
        product: {
            view: 'Vis',
            cashback: 'Penge tilbage',
            fiche: 'Produktark',
            view_product: 'Se produkt',
            loading: 'Indlæser...',
            filter: 'Filtrer efter funktioner',
            update: 'Opdatering',
            colour: 'Farve',
            features: 'Funktioner',
            capacity: 'Kapacitet',
            type: 'Type',
            size: 'Størrelse',
            compare : 'Sammenligne',
            of_4 : 'af 4',
            compare_up_to : 'Sammenlign op til 4 varer',
            showing: 'Viser',
            product: 'Produkt',
            products: 'Produkter',
            buy_now: 'Køb nu',
            no_products: 'Ingen produkter',
        },
        compare: {
            looking: 'På udkig efter produkter at sammenligne...',
            no_products_to_compare: 'Ingen produkter at sammenligne',
            no_products: 'Ingen produkter',
            title: 'Produktsammenligning',
        },
        search: {
            news: 'Nyheder & Blog',
            search_for: 'Søge efter:',
            search_results_for: 'Søgeresultater for:',
            result: 'resultat',
            results: 'resultater',
            no_results: 'Ingen resultater til din søgning!',
            no_results_explore: 'Ingen resultater til din søgning, men der er masser at udforske!',
            explore: 'Udforsk vores produkter',
            click_here: 'Klik her',
            searching: 'Søger...',
        },
        where_to_buy: {
            retailers: 'På udkig efter forhandlere...',
            stock_availability: 'Lagertilgængelighed og priser vil variere, så kontakt forhandleren direkte for at få opdaterede oplysninger.',
            in_stock: 'På lager*',
            call_store_for_stock: 'Ring til butik for lager*',
            view_online: 'Se online',
            directions: 'Kørselsvejledning',
            call_store: 'Ring til butik',
            available: 'Tilgængelig i:',
            online: 'Online',
            in_store: 'På lager',
            no_results: 'Ingen resultater fundet'
        }
    },
    en: {
        product: {
            view: 'View',
            cashback: 'Cashback',
            fiche: 'Product Fiche',
            view_product: 'View Product',
            loading: 'Loading...',
            filter: 'Filter by features',
            update: 'Update',
            colour: 'Colour',
            features: 'Features',
            capacity: 'Capacity',
            type: 'Type',
            size: 'Size',
            compare : 'Compare',
            of_4 : 'of 4',
            compare_up_to : 'Compare up to 4 items',
            showing: 'Showing',
            product: 'Product',
            products: 'Products',
            buy_now: 'Buy Now',
            no_products: 'No Products',
        },
        compare: {
            looking: 'Looking for products to compare...',
            looking: 'Looking for products to compare...',
            no_products_to_compare: 'No products to compare',
            title: 'Product Comparison',
        },
        search: {
            news: 'News & Blog',
            search_for: 'Searching for:',
            search_results_for: 'Search results for:',
            result: 'result',
            results: 'results',
            no_results: 'No results for your search!',
            no_results_explore: 'No results for your search but there’s plenty to explore!',
            explore: 'Explore our products',
            click_here: 'Click here',
            searching: 'Searching ...',
        },
        where_to_buy: {
            retailers: 'Looking for retailers...',
            stock_availability: 'Stock availability and prices will vary, so please contact the retailer directly for up to date information.',
            in_stock: 'In Stock*',
            call_store_for_stock: 'Call store for stock*',
            view_online: 'View online',
            directions: 'Directions',
            call_store: 'Call Store',
            available: 'Available In:',
            online: 'Online',
            in_store: 'In Store',
            no_results: 'No results found'
        }
    },
    fi: {
        product: {
            view: 'Näytä',
            cashback: 'Vaihtorahat',
            fiche: 'Tuotesivu',
            view_product: 'Näytä tuote',
            loading: 'Ladataan...',
            filter: 'Suodata ominaisuuksien mukaan',
            update: 'Päivittää',
            colour: 'Väri',
            features: 'Ominaisuudet',
            capacity: 'Kapasiteetti',
            type: 'Tyyppi',
            size: 'Koko',
            compare : 'Vertailla',
            of_4 : '/ 4',
            compare_up_to : 'Vertaa jopa 4 tuotetta',
            showing: 'Näytetään',
            product: 'Tuote',
            products: 'tuotetta',
            buy_now: 'Osta nyt',
            no_products: 'Ei tuotteita',
        },
        compare: {
            looking: 'Etsitään vertailtavia tuotteita ...',
            no_products_to_compare: 'Ei vertailtavia tuotteita',
            no_products: 'Ei tuotteita',
            title: 'Tuotevertailu',
        },
        search: {
            news: 'Uutiset ja blogi',
            search_for: 'Etsimässä:',
            search_results_for: 'Etsi tuloksia:',
            result: 'tulos',
            results: 'tuloksia',
            no_results: 'Ei tuloksia haullesi!',
            no_results_explore: 'Ei tuloksia haullesi, mutta tutkittavaa on paljon!',
            explore: 'Tutustu tuotteisiimme',
            click_here: 'Klikkaa tästä',
            searching: 'Etsitään...',
        },
        where_to_buy: {
            retailers: 'Etsitään jälleenmyyjiä...',
            stock_availability: 'Varastojen saatavuus ja hinnat vaihtelevat, joten ota yhteyttä suoraan jälleenmyyjään saadaksesi ajantasaiset tiedot.',
            in_stock: 'Varastossa*',
            call_store_for_stock: 'Soita varastoon*',
            view_online: 'Näytä verkossa',
            directions: 'Ohjeet',
            call_store: 'Soita kauppaan',
            available: 'Saatavilla:',
            online: 'Verkossa',
            in_store: 'Varastossa',
            no_results: 'Ei tuloksia'
        }
    },
    no: {
        product: {
            view: 'Vis',
            cashback: 'Penger tilbake',
            fiche: 'Produktinformasjon',
            view_product: 'Vis produkt',
            loading: 'Laster inn...',
            filter: 'Filtrer etter funksjoner',
            update: 'Oppdater',
            colour: 'Farge',
            features: 'Funksjoner',
            capacity: 'Kapasitet',
            type: 'Type',
            size: 'Størrelse',
            compare : 'Sammenligne',
            of_4 : 'av 4',
            compare_up_to : 'Sammenlign opptil 4 varer',
            showing: 'Viser',
            product: 'Produkt',
            products: 'Produkter',
            buy_now: 'Kjøp nå',
            no_products: 'Ingen produkter',
        },
        compare: {
            looking: 'Leter etter produkter å sammenligne...',
            no_products_to_compare: 'Ingen produkter å sammenligne',
            no_products: 'Ingen produkter',
            title: 'Produktsammenligning',
        },
        search: {
            news: 'Nyheter og blogg',
            search_for: 'Leter etter:',
            search_results_for: 'Søkeresultater for:',
            result: 'resultat',
            results: 'resultater',
            no_results: 'Ingen resultater for søket ditt!',
            no_results_explore: 'Ingen resultater for søket ditt, men det er mye å utforske!',
            explore: 'Utforsk produktene våre',
            click_here: 'Klikk her',
            searching: 'Søker ...',
        },
        where_to_buy: {
            retailers: 'Ser etter forhandlere...',
            stock_availability: 'Lagertilgjengelighet og priser vil variere, så kontakt forhandleren direkte for oppdatert informasjon.',
            in_stock: 'På lager*',
            call_store_for_stock: 'Ring butikk for lager*',
            view_online: 'Vis online',
            directions: 'Veibeskrivelse',
            call_store: 'Ring butikken',
            available: 'Tilgjengelig i:',
            online: 'På nett',
            in_store: 'I butikken',
            no_results: 'Ingen resultater'
        }
    },
    se: {
        product: {
            view: 'Visa',
            cashback: 'Pengar tillbaka',
            fiche: 'Produktinformation',
            view_product: 'Visa produkt',
            loading: 'Läser in...',
            filter: 'Filtrera produkter',
            update: 'Uppdatering',
            colour: 'Färg',
            features: 'Funktioner',
            capacity: 'Kapacitet',
            type: 'Typ',
            size: 'Storlek',
            compare : 'Jämföra',
            of_4 : 'av 4',
            compare_up_to : 'Jämför upp till 4 artiklar',
            showing: 'Visar',
            product: 'Produkt',
            products: 'Produkter',
            buy_now: 'Köp nu',
            no_products: 'Inga produkter',
        },
        compare: {
            looking: 'Letar efter produkter att jämföra...',
            no_products_to_compare: 'Inga produkter att jämföra',
            no_products: 'Inga produkter',
            title: 'Produktjämförelse',
        },
        search: {
            news: 'Nyheter & blogg',
            search_for: 'Söker efter:',
            search_results_for: 'Sökresultat för:',
            result: 'resultat',
            results: 'resultat',
            no_results: 'Inga resultat för din sökning!',
            no_results_explore: 'Inga resultat för din sökning men det finns mycket att utforska!',
            explore: 'Utforska våra produkter',
            click_here: 'Klicka här',
            searching: 'Forskande...',
        },
        where_to_buy: {
            retailers: 'Letar efter återförsäljare...',
            stock_availability: 'Lagertillgänglighet och priser varierar, så kontakta återförsäljaren direkt för uppdaterad information.',
            in_stock: 'I lager*',
            call_store_for_stock: 'Ring butik för lager*',
            view_online: 'Visa online',
            directions: 'Vägbeskrivning',
            call_store: 'Ring butik',
            available: 'Tillgänglig i:',
            online: 'Uppkopplad',
            in_store: 'I lager',
            no_results: 'Inga resultat funna'
        }
    }
};

export default translations;