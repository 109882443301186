<template>
    <div class="loading-screen wrapper">
        <div class="pli pli-dummy loading">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
        <div class="pli pli-dummy loading">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
        <div class="pli pli-dummy loading">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        }
    }
</script>