<template>
    <div>
        <comparison-bar v-if="comparisons.hood.length > 0" :product_type="'hoods'" :compare_products="comparisons.hood"></comparison-bar>
        <div class="wrapper">
            <loading-screen v-if="products.loading == true"></loading-screen>
            <product-list-item v-else v-for="product in products.current_products" :product="product" :key="product.id"></product-list-item>
            <no-products v-if="products.current_products == undefined && products.loading == false"></no-products>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import LoadingScreen from './../LoadingScreen';
    import ComparisonBar from './../ComparisonBar';
    import ProductListItem from './../ListItem';
    import NoProducts from './../NoProducts';
    import {updateViewItemListDataLayer} from '../../../helpers/datalayer';

    var urlParts = window.location.pathname.split('/');
    if( urlParts[0] == '') urlParts.shift();
    if( urlParts[urlParts.length - 1] == '') urlParts.pop();

    export default {
        components: {
            LoadingScreen,
            ComparisonBar,
            ProductListItem,
            NoProducts
        },
        computed: {
            ...mapState(
				['products', 'comparisons'],
            )
        },
        methods: {
            setDefaultFilters( filters ) {
                let vm = this;

                if( filters.hood_colour != undefined ) {
                    filters.hood_colour.forEach(function(colour) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'hood_colour', 'product_type' : 'hood', 'value' : colour } );
                    });
                }

                if( filters.hood_types != undefined ) {
                    filters.hood_types.forEach(function(type) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'hood_types', 'product_type' : 'hood', 'value' : type } );
                    });
                }

                if( filters.hood_features != undefined ) {
                    filters.hood_features.forEach(function(feature) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'hood_features', 'product_type' : 'hood', 'value' : feature } );
                    });
                }

            },
            setFilterString() {

                let vm = this;

                var append = '?',
                    hood_colours = [],
                    hood_features = [],
                    hood_types = [];
                
                if( vm.$store.state.products.active_filters.hood.colours.length > 0 ){

                    vm.$store.state.products.active_filters.hood.colours.forEach(function(colour) {
                        hood_colours.push(colour.slug);
                    });

                    hood_colours = hood_colours.join(',');

                    append = append + '&hood_colour=' + hood_colours;
                }

                if( vm.$store.state.products.active_filters.hood.features.length > 0 ){

                    vm.$store.state.products.active_filters.hood.features.forEach(function(feature) {
                        hood_features.push(feature.slug);
                    });

                    hood_features = hood_features.join('+');

                    append = append + '&hood_features=' + hood_features;
                }

                if( vm.$store.state.products.active_filters.hood.types.length > 0 ){

                    vm.$store.state.products.active_filters.hood.types.forEach(function(type) {
                        hood_types.push(type.slug);
                    });

                    hood_types = hood_types.join(',');

                    append = append + '&hood_types=' + hood_types;
                }
                
                append = append.replace("?&", "?");
                if( append == '?' ) append = '';

                return append;
            },
            resetProducts() {
                let vm = this; 

                var append = '?',
                    hood_colours = [],
                    hood_features = [],
                    hood_types = [];

                vm.$store.dispatch('updateProductLoadingStatus', true );

                hood_colours = vm.getUrlParameter('hood_colour');
                hood_features = vm.getUrlParameter('hood_features');
                hood_types = vm.getUrlParameter('hood_types');

                if(urlParts[2] == 'features' && urlParts[3] !== ''){
                    hood_features = urlParts[3];
                } else if(urlParts[2] == 'type' && urlParts[3] !== ''){
                    hood_types = urlParts[3];
                }

                if( hood_colours != undefined ) {
                    append = append + '&hood_colour=' + hood_colours;
                }
                if( hood_features != undefined ) {
                    append = append + '&hood_features=' + hood_features;
                }
                if( hood_types != undefined ) {
                    append = append + '&hood_types=' + hood_types;
                }

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-hoods/' + append,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.setDefaultFilters( response.filters );
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            }
        },
        watch: {
            '$store.state.products.current_products': {
                handler: function(currentProducts) {
                    updateViewItemListDataLayer(currentProducts, 'Hood List');
                },
                deep: true
            }
        },
        mounted(){
            let vm = this;

            vm.resetProducts();

            Event.$on( 'update_products', ($event) => {

                vm.$store.dispatch('updateProductLoadingStatus', true );

                var queryURL = vm.setFilterString();
                var pushURL = '/' + urlParts[0] + '/' + urlParts[1] + '/' + queryURL;

                window.history.pushState('', '', pushURL);

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-hoods/' + queryURL,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            });

        },
        data() {
            return {
            }
        }
    }

</script>