<template>
    <div itemscope itemtype="http://schema.org/Product" :class="product_class" v-match-heights="{ el: ['p.product-title', '.product-meta'] }">
        <link itemprop="url" :href="current_product.content.links.view" />
        <div class="row">

            <template v-if="current_product.content.type == 'tv'">
                <div v-if="has_variants" :class="'col sizes product-' + current_product.content.ID">
                    <div v-for="(variant, index) in current_product.content.variants" :key="index" :data-value="variant.size.slug" class="size" :class="{ active : activeVariant( index ) }" @click="showVariant( index )">{{ variant.size.name }}</div>
                </div>
                <div v-else :class="'col sizes product-' + current_product.content.ID">
                    <div v-for="(size, index) in current_product.content.tv_sizes" :key="index" :data-value="size.slug" class="size" :class="{ active : activeVariant( index ) }">{{ size.name }}</div>
                </div>
            </template>
            <template v-else>
                <div :class="'col sizes product-' + current_product.content.ID">
                    <div v-for="(variant, index) in current_product.variants" :key="index" :data-value="variant.colour.slug" :html="variant.name" class="size size-colors" :class="{ active : activeVariant( current_product.content.colour.slug, variant.colour.slug, index ) }" @click="showVariant( index )"></div>
                </div>
            </template>

            <div class="col product-info">
                <div class="product-image">
                    <div class="p-image__sticker" v-if="current_product.content.images.sticker_present" v-html="current_product.content.images.sticker"></div>

                    <a @click.prevent="handleBuyNowClick(current_product)" v-if="current_product.content.images" :href="current_product.content.links.view" :data-product-id="current_product.content.ID">
                        <div class="product-image-wrapper" v-html="current_product.content.images.main"></div>
                        <div class="view">{{ $t('product.view') }}</div>
                    </a>
                    <div v-if="current_product.content.laundry_size" class="laundry-size">
                        <span>{{ current_product.content.laundry_size.name }}</span>
                    </div>
                    <div v-if="current_product.content.fridge_size" class="fridge-width">
                        <span>{{ current_product.content.fridge_size }}</span>
                        <simple-svg :filepath="theme_path + 'images/svg/arrow-width.svg'" :width="'64px'" :height="'12px'" />
                    </div>
                    <component v-if="current_product.content.promotion" :is="current_product.content.promotion.link !== '' ? 'a' : 'span'" :href="current_product.content.promotion.link || ''" class="promotion">
                        <span v-if="current_product.content.promotion.listing_label" v-html="current_product.content.promotion.listing_label"></span>
                        <span v-else v-html="current_product.content.promotion.label"></span>
                    </component>
                    <a v-if="current_product.content.cashback" :href="current_product.content.cashback.link" class="cashback">
                        <span>{{ $t('product.cashback') }}</span>
                    </a>
                    <div v-if="current_product.content.new" class="new">
                        <span v-if="current_product.content.new.listing_label">{{ current_product.content.new.listing_label }}</span>
                        <span v-else>{{ current_product.content.new.label }}</span>
                    </div>
                </div>

                <div class="product-meta">
                    <div v-if="current_product.content.product_rating" class="col reviews" v-html="current_product.content.product_rating"></div>
                    <div v-if="current_product.content.energy_ratings.product_fiche || current_product.content.energy_ratings.energy_efficiency" class="col energy-ratings">
                        <a v-if="current_product.content.energy_ratings.product_fiche" class="product-fiche" :href="current_product.content.energy_ratings.product_fiche" target="_blank">{{ $t('product.fiche') }}</a>
                        <a v-if="current_product.content.energy_ratings.energy_efficiency && current_product.content.energy_ratings.energy_efficiency.link" :href="current_product.content.energy_ratings.energy_efficiency.link" target="_blank">
                            <span :class="'energy-efficiency ' + current_product.content.energy_ratings.energy_efficiency.class">{{ current_product.content.energy_ratings.energy_efficiency.rating }}</span>
                        </a>
                        <span v-else-if="current_product.content.energy_ratings.energy_efficiency" :class="'energy-efficiency ' + current_product.content.energy_ratings.energy_efficiency.class">{{ current_product.content.energy_ratings.energy_efficiency.rating }}</span>
                    </div>
                </div>
                <div class="product-title">
                    <p class="product-title" itemprop="name">
                        <a :href="current_product.content.links.view">{{ current_product.content.name }}</a>
                    </p>
                </div>
                <div v-if="current_product.content.key_features" class="product-standout">
                    <div class="features">
                        <div v-for="(current, index) in current_product.content.key_features" :key="index" class="feature" :data-type="current.feature.slug" @mouseover="featureLabels(current.feature.name)" @mouseout="featureLabels('')">
                            <div class="inner">
                                <img v-if="current.feature_icon" :title="current.feature.name" :src="current.feature_icon.url" />
                                <img v-else :title="current.feature.name" :src="theme_path + 'images/svg/features/icon-' + current.feature.slug + '.svg'" />
                            </div>
                        </div>
                    </div>
                    <p class="feature-label" v-html="feature_label"></p>
                </div>
                <div v-if="current_product.content.links" class="product-actions">
                    <where-to-buy-button :product_id="current_product.content.ID" v-if="current_product.content.links.where_to_buy" :key="current_product.content.ID"></where-to-buy-button>
                    <a @click.prevent="handleBuyNowClick(current_product)" v-if="current_product.content.links.view" class="button button-view" :href="current_product.content.links.view" :data-product-id="current_product.content.ID" :data-text="$t('product.view_product')"><span>{{ $t('product.view_product') }}</span></a>
                </div>
                <div class="compare-products" :class="{ disabled : cant_compare }">
                    <label :for="'compare-' + current_product.content.ID" v-html="compare_label"></label>
                    <input :for="'compare-' + current_product.content.ID" type="checkbox" v-model="compare_product" :disabled="cant_compare" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import WhereToBuyButton from './../WhereToBuy/Button';

    export default {
        props: [ 'product' ],
        components: {
            WhereToBuyButton
        },
        mounted(){
            let vm = this;
            vm.current_product = vm.product;

            console.log('Sticker: ', vm.current_product.content.images.sticker);
        },
        computed: {
            ...mapState(
				['where_to_buy'],
            ),
            cant_compare: function(){
                let vm = this;

                var product_type = vm.current_product.content.type;

                if( vm.$store.state.comparisons[product_type] && vm.$store.state.comparisons[product_type].length >= 4 ) {
                    if ( vm.compare_product == true) {
                        return false;
                    } else {
                        return true;
                    }
                }
                else return false;
            },
            compare_product: {
                get(product) {
                    let vm = this,
                        compare_index = 0;

                    var product_type = vm.current_product.content.type;

                    if( vm.$store.state.comparisons[product_type] ) {
                        compare_index = vm.$store.state.comparisons[product_type].findIndex(product => product.ID === vm.current_product.content.ID);

                        if ( compare_index > -1 ) {
                            vm.compare_label = vm.$t('product.compare') + ' ' + ( compare_index + 1 ) + ' ' + vm.$t('product.of_4');
                            return true;
                        }
                        else {
                            vm.compare_label = vm.$t('product.compare_up_to');
                            return false;
                        }
                    }

                    return false;
                },
                set(product) {
                    let vm = this;
                    vm.$store.dispatch( 'updateProductCompare', { 'product_type' : vm.current_product.content.type, 'product' : vm.current_product.content } );
                }
            },
            has_variants: function(){
                let vm = this;

                if( vm.current_product.content.variants !== undefined ){
                    if( vm.current_product.content.variants[0].size !== null ) {
                        return true;
                    }
                }

                return false;
            },
            product_class: function(){
                let vm = this;
                var product_class = 'pli swiper-slide pli-' + vm.current_product.content.type;

                return product_class;
            }
        },
        methods: {
            activeVariant: function ( main_variant, current_variant, index ) {

                if( current_variant == undefined && index == undefined ) {
                    if( main_variant == 0 ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if( main_variant == current_variant ) {
                        return true;
                    } else {
                        return false;
                    }
                }

            },
            showVariant: function (index) {
                let vm = this;

                if( vm.current_product.variants !== null ) {
                    var maxHeight = Math.max.apply(null, jQuery('.product-image').map(function () {
                        return jQuery(this).outerHeight();
                    }).get());

                    jQuery('.new-products.' + vm.current_product.content.type + ' .product-info .product-image').css({ 'min-height' : maxHeight });

                    jQuery('.col.sizes.product-' + vm.current_product.content.ID + ' .size').removeClass('active');
                    jQuery('.col.sizes.product-' + vm.current_product.content.ID + ' .size').eq(index).addClass('active');

                    if( vm.current_product.content.type == 'tv' ) {
                        //Update TV Variant
                        vm.current_product.content.name = vm.current_product.content.variants[index].model_number;
                        vm.current_product.content.size = vm.current_product.content.variants[index].size;
                        vm.current_product.content.energy_ratings = vm.current_product.content.variants[index].energy_ratings;

                    } else {
                        vm.current_product.content = vm.current_product.variants[index];
                    }
                }

            },
            handleBuyNowClick: function(product) {
                if (typeof dataLayer !== 'undefined') {     
                    dataLayer.push({
                        'event': 'select_item',
                        'item_list_name': 'Product List - ' + product.content.type,
                        'items': {
                            'item_id': product.content.ID,
                            'item_name': product.content.name,
                            'item_category': product.content.type
                        }
                    });

                    // Go to link
                    window.location.href = product.content.links.view;
                }
            }
        },
        data() {
            return {
                current_product : {
                    content: {
                        ID: null,
                        color: {},
                        energy_ratings: {
                            product_fiche: null,
                            energy_efficiency: []
                        },
                        image: null,
                        links: {
                            view: null
                        },
                        model: null,
                        name: null,
                        new: false,
                        product_rating: null,
                        type: null
                    },
                    variants: []
                },
                userLocation: null,
                loading : false,
                feature_label: '',
                compare_label: 'Compare up to 4 items'
            }
        }
    }
</script>
