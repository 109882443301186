<template>
    <div>
        <div class="found-count">
            <p v-html="found_count"></p>
        </div>
        <div class="wrapper">
            <single-result v-for="post in results" :post="post" :key="post.id"></single-result>
        </div>
    </div>
</template>
<script>
    import SingleResult from './Single';

    export default {
        props: [ 'results' ],
        components: {
            SingleResult
        },
        computed: {
            found_count: function(){
                let vm = this;
                return vm.$t('search.news') + ' <span>(' + vm.results.length + ')</span>';
            }
        },
        data() {
            return { }
        }
    }
</script>