import LaundryProductFilter from '../../components/Products/Laundry/Filter';
import LaundryProductList from '../../components/Products/Laundry/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveLaundry = {
  element: '#archive-laundry',
  components: {
    LaundryProductFilter,
    LaundryProductList,
    WhereToBuy
  }
}

export default archiveLaundry;