import Vue from 'vue';

Vue.mixin({
    computed: {
        product_class: function() {
            let vm = this;

            var product_class;

            if( vm.current_product !== undefined && vm.current_product.content !== undefined ) {
                product_class = 'pli pli-' + vm.current_product.content.type;
            } else {
                product_class = 'pli';
            }

            if( vm.loading ) product_class += ' loading';

            return product_class;
        },
        product_count: function() {
            let vm = this;

            if( vm.products !== undefined && vm.products.current_products !== undefined ) {
                if( vm.products.current_products.length > 1 ) {
                    return vm.$t('product.showing') + ' ' + vm.products.current_products.length + ' ' + vm.$t('product.products');
                } else {
                    return vm.$t('product.showing') + ' ' + vm.products.current_products.length + ' ' + vm.$t('product.product');
                }
            }
            else return vm.$t('product.showing') + ' 0 ' + vm.$t('product.products');
        },
        theme_path: function(){
            return '/app/themes/hisense/assets/dist/';
        },
    },
    methods: {
        cleanText: function( text ) {
            text = decodeURI(text);
            text = text.replace(/&amp;/g, "&");
            return text;
        },
        getUrlParameter(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    var filter = decodeURIComponent(sParameterName[1]);

                    return filter;
                }
            }
        },
        featureLabels: function( label ){
            let vm = this;
            vm.feature_label = vm.cleanText(label);
        },
        toggleCompare: function ( product_type, product ) {
            let vm = this;
            vm.$store.dispatch( 'updateProductCompare', { 'product_type' : product_type, 'product' : product } );
        },
        activeFilter ( name, value ) {
            let filter_index = 0;

            if( name == 'dishwasher_colour' ) {
                filter_index = this.products.active_filters.dishwasher.colours.findIndex(colour => colour.slug === value.slug)
            } else if( name == 'dishwasher_features' ) {
                filter_index = this.products.active_filters.dishwasher.features.findIndex(feature => feature.slug === value.slug)
            } else if( name == 'dishwasher_type' ) {
                filter_index = this.products.active_filters.dishwasher.types.findIndex(type => type.slug === value.slug)
            } else if( name == 'fridge_colour' ) {
                filter_index = this.products.active_filters.refrigeration.colours.findIndex(colour => colour.slug === value.slug)
            } else if( name == 'fridge_size' ) {
                filter_index = this.products.active_filters.refrigeration.sizes.findIndex(size => size.slug === value.slug)
            } else if( name == 'fridge_type' ) {
                filter_index = this.products.active_filters.refrigeration.types.findIndex(type => type.slug === value.slug)
            } else if( name == 'fridge_features' ) {
                filter_index = this.products.active_filters.refrigeration.features.findIndex(feature => feature.slug === value.slug)
            } else if( name == 'hob_colour' ) {
                filter_index = this.products.active_filters.hob.colours.findIndex(type => type.slug === value.slug)
            } else if( name == 'hob_type' ) {
                filter_index = this.products.active_filters.hob.types.findIndex(type => type.slug === value.slug)
            } else if( name == 'hob_features' ) {
                filter_index = this.products.active_filters.hob.features.findIndex(feature => feature.slug === value.slug)
            } else if( name == 'hood_colour' ) {
                filter_index = this.products.active_filters.hood.colours.findIndex(colour => colour.slug === value.slug)
            } else if( name == 'hood_type' ) {
                filter_index = this.products.active_filters.hood.types.findIndex(type => type.slug === value.slug)
            } else if( name == 'hood_features' ) {
                filter_index = this.products.active_filters.hood.features.findIndex(feature => feature.slug === value.slug)
            } else if( name == 'laundry_colour' ) {
                filter_index = this.products.active_filters.laundry.colours.findIndex(colour => colour.slug === value.slug)
            } else if( name == 'laundry_features' ) {
                filter_index = this.products.active_filters.laundry.features.findIndex(feature => feature.slug === value.slug)
            } else if( name == 'laundry_size' ) {
                filter_index = this.products.active_filters.laundry.sizes.findIndex(size => size.slug === value.slug)
            } else if (name == 'laundry_type') {
                filter_index = this.products.active_filters.laundry.types.findIndex(type => type.slug === value.slug)
            } else if (name == 'oven_colour') {
                filter_index = this.products.active_filters.oven.colours.findIndex(colour => colour.slug === value.slug)
            } else if( name == 'oven_features' ) {
                filter_index = this.products.active_filters.oven.features.findIndex(feature => feature.slug === value.slug)
            } else if (name == 'cooker_colour') {
                filter_index = this.products.active_filters.cooker.colours.findIndex(colour => colour.slug === value.slug)
            } else if (name == 'cooker_features') {
                filter_index = this.products.active_filters.cooker.features.findIndex(feature => feature.slug === value.slug)
            } else if (name == 'microwave_capacity') {
                filter_index = this.products.active_filters.microwave.capacities.findIndex(capacity => capacity.slug === value.slug)
            } else if (name == 'microwave_colour') {
                filter_index = this.products.active_filters.microwave.colours.findIndex(colour => colour.slug === value.slug)
            } else if (name == 'microwave_features') {
                filter_index = this.products.active_filters.microwave.features.findIndex(feature => feature.slug === value.slug)
            } else if( name == 'tv_features' ) {
                filter_index = this.products.active_filters.tv.features.findIndex(feature => feature.slug === value.slug)
            } else if( name == 'tv_sizes' ) {
                var filter_value = value.values.join(',');
                filter_index = this.products.active_filters.tv.sizes.findIndex(size => size.values.join(',') === filter_value)
            } else if (name == 'soundbar_features') {
                filter_index = this.products.active_filters.soundbar.features.findIndex(feature => feature.slug === value.slug)
            }

            if ( filter_index > -1 ) {
                return true;
            }
            else {
                return false;
            }
        },
        toggleFilter ( name, value ) {
            let vm = this;

            if( name == 'dishwasher_colour' || name == 'dishwasher_features' || name == 'dishwasher_type' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'dishwasher', 'value' : value } );
            } else if( name == 'fridge_colour' || name == 'fridge_features' || name == 'fridge_size' || name == 'fridge_type' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'refrigeration', 'value' : value } );
            } else if( name == 'hob_colour' ||  name == 'hob_features' || name == 'hob_type' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'hob', 'value' : value } );
            } else if( name == 'hood_colour' || name == 'hood_features' || name == 'hood_type' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'hood', 'value' : value } );
            } else if (name == 'laundry_colour' || name == 'laundry_features' || name == 'laundry_size' || name == 'laundry_type' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'laundry', 'value' : value } );
            } else if( name == 'oven_colour' || name == 'oven_features' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'oven', 'value' : value } );
            } else if( name == 'cooker_colour' || name == 'cooker_features' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'cooker', 'value' : value } );
            } else if ( name == 'microwave_capacity' || name == 'microwave_colour' || name == 'microwave_features ') {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'microwave', 'value' : value } );
            } else if( name == 'tv_features' || name == 'tv_sizes' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'tv', 'value' : value } );
            } else if( name == 'soundbar_features' || name == 'soundbar_size' ) {
                vm.$store.dispatch( 'updateProductFilter', { 'name' : name, 'product_type' : 'soundbar', 'value' : value } );
            }

            Event.$emit('update_products');
        }
    }
});