import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const singleProductPage = {
  element: '#single-product',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default singleProductPage;