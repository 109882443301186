<template>
    <div>
        <comparison-bar v-if="comparisons.oven.length > 0" :product_type="'ovens'" :compare_products="comparison.oven"></comparison-bar>
        <div class="wrapper">
            <loading-screen v-if="products.loading == true"></loading-screen>
            <product-list-item v-else v-for="product in products.current_products" :product="product" :key="product.id"></product-list-item>
            <no-products v-if="products.current_products == undefined && products.loading == false"></no-products>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import LoadingScreen from './../LoadingScreen';
    import ComparisonBar from './../ComparisonBar';
    import ProductListItem from './../ListItem';
    import NoProducts from './../NoProducts';
    import {updateViewItemListDataLayer} from '../../../helpers/datalayer';

    var urlParts = window.location.pathname.split('/');
    if( urlParts[0] == '') urlParts.shift();
    if( urlParts[urlParts.length - 1] == '') urlParts.pop();

    export default {
        components: {
            LoadingScreen,
            ComparisonBar,
            ProductListItem,
            NoProducts
        },
        computed: {
            ...mapState(
				['products', 'comparisons'],
            )
        },
        methods: {
            setDefaultFilters( filters ) {
                let vm = this;

                if( filters.oven_colour != undefined ) {
                    filters.oven_colour.forEach(function(colour) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'oven_colour', 'product_type' : 'oven', 'value' : colour } );
                    });
                }

                if( filters.oven_types != undefined ) {
                    filters.oven_types.forEach(function(colour) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'oven_types', 'product_type' : 'oven', 'value' : colour } );
                    });
                }

                if( filters.oven_features != undefined ) {
                    filters.oven_features.forEach(function(feature) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'oven_features', 'product_type' : 'oven', 'value' : feature } );
                    });
                }

            },
            setFilterString() {
                let vm = this;

                var append = '?',
                    oven_colours = [],
                    oven_types = [],
                    oven_features = [];

                if( vm.$store.state.products.active_filters.oven.colours.length > 0 ){

                    vm.$store.state.products.active_filters.oven.colours.forEach(function(colour) {
                        oven_colours.push(colour.slug);
                    });

                    oven_colours = oven_colours.join(',');

                    append = append + '&oven_colour=' + oven_colours;
                }

                if( vm.$store.state.products.active_filters.oven.types.length > 0 ){

                    vm.$store.state.products.active_filters.oven.types.forEach(function(type) {
                        oven_types.push(type.slug);
                    });

                    oven_types = oven_types.join(',');

                    append = append + '&oven_types=' + oven_types;
                }

                if( vm.$store.state.products.active_filters.oven.features.length > 0 ){

                    vm.$store.state.products.active_filters.oven.features.forEach(function(feature) {
                        oven_features.push(feature.slug);
                    });

                    oven_features = oven_features.join('+');

                    append = append + '&oven_features=' + oven_features;
                }
                
                append = append.replace("?&", "?");
                if( append == '?' ) append = '';

                return append;
            },
            resetProducts() {
                let vm = this; 

                var append = '?',
                    oven_colours = [],
                    oven_types = [],
                    oven_features = [];

                vm.$store.dispatch('updateProductLoadingStatus', true );

                oven_colours = vm.getUrlParameter('oven_colour');
                oven_types = vm.getUrlParameter('oven_types');
                oven_features = vm.getUrlParameter('oven_features');

                if(urlParts[2] == 'colour' && urlParts[3] !== ''){
                    oven_colours = urlParts[3];
                } else if(urlParts[2] == 'features' && urlParts[3] !== ''){
                    oven_features = urlParts[3];
                } else if(urlParts[2] == 'types' && urlParts[3] !== ''){
                    oven_types = urlParts[3];
                }

                 if( oven_colours != undefined ) {
                    append = append + '&oven_colour=' + oven_colours;
                }

                if( oven_types != undefined ) {
                    append = append + '&oven_types=' + oven_types;
                }

                if( oven_features != undefined ) {
                    append = append + '&oven_features=' + oven_features;
                }

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-ovens/' + append,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.setDefaultFilters( response.filters );
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            }
        },
        watch: {
            '$store.state.products.current_products': {
                handler: function(currentProducts) {
                    updateViewItemListDataLayer(currentProducts, 'Oven List');
                },
                deep: true
            }
        },
        mounted(){
            let vm = this;

            vm.resetProducts();

            Event.$on( 'update_products', ($event) => {

                vm.$store.dispatch('updateProductLoadingStatus', true );

                var queryURL = vm.setFilterString();
                var pushURL = '/' + urlParts[0] + '/' + urlParts[1] + '/' + queryURL;

                window.history.pushState('', '', pushURL);

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-ovens/' + queryURL,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            });

        },
        data() {
            return {
            }
        }
    }

</script>