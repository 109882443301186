import SoundbarProductFilter from '../../components/Products/Soundbar/Filter';
import SoundbarProductList from '../../components/Products/Soundbar/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveSoundbar = {
    element: '#archive-soundbar',
    components: {
        SoundbarProductFilter,
        SoundbarProductList,
        WhereToBuy
    }
}

export default archiveSoundbar;