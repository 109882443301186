<template>
    <div class="meta__sizes" v-if="where_to_buy.product.eans && where_to_buy.product.eans.length > 1">
        <!-- <p class="title">{{ $t('where_to_buy.available') }}</p>
        <div class="meta__sizes--inner" v-if="where_to_buy.product.show_sizes">
            <!-- <template v-for="(ean, index) in where_to_buy.product.eans">
                <a v-if="ean.size !== null" :key="index" :data-size="ean.size.slug" class="size" v-html="ean.size.name" @click="toggleWTBEAN(index)"></a>
            </template> 
        </div>
        <div class="meta__sizes--inner" v-if="where_to_buy.product.show_colours">
            <template v-for="(ean, index) in where_to_buy.product.eans">
                <a v-if="ean.colour !== null" :key="index" :data-size="ean.colour.slug" class="size" @click="toggleWTBEAN(index)"></a>
            </template>
        </div> -->
   </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        computed: {
            ...mapState(
                ['where_to_buy'],
            )
        },
        mounted(){
            Event.$on( 'open_wtb', ($event) => {
                jQuery('.meta__sizes--inner .size.active, .product-wtb__results.active').removeClass('active');
                jQuery('.meta__sizes--inner .size').eq(0).addClass('active');
                jQuery('.product-wtb__results').eq(0).addClass('active');
            });
        },
        methods: {
            toggleWTBEAN: function( index ){
                jQuery('.meta__sizes--inner .size.active, .product-wtb__results.active').removeClass('active');
                jQuery('.meta__sizes--inner .size').eq(index).addClass('active');

                jQuery('.product-wtb__results').eq(index).addClass('active');

                jQuery('.wtb-marker-parent.active').removeClass('active');
                jQuery('.wtb-marker-parent .info-window').hide();

                jQuery('.product-wtb__results .local-retailers__list').scrollTop(0);
            }
        }
    }
</script>