import SearchResults from '../../components/Search/Results/Index';
import WhereToBuy from '../../components/WhereToBuy/Index';

const searchResultsPage = {
  element: '#search-results',
  components: {
    SearchResults,
    WhereToBuy
  }
}

export default searchResultsPage;