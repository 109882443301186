<template>
    <div>
        <comparison-bar v-if="comparisons.refrigeration.length > 0" :product_type="'refrigeration'" :compare_products="comparisons.refrigeration"></comparison-bar>
        <div class="wrapper">
            <loading-screen v-if="products.loading == true"></loading-screen>
            <product-list-item v-else v-for="product in products.current_products" :product="product" :key="product.id"></product-list-item>
            <no-products v-if="products.current_products == undefined && products.loading == false"></no-products>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import LoadingScreen from './../LoadingScreen';
    import ComparisonBar from './../ComparisonBar';
    import ProductListItem from './../ListItem';
    import NoProducts from './../NoProducts';
    import {updateViewItemListDataLayer} from '../../../helpers/datalayer';

    var urlParts = window.location.pathname.split('/');
    if( urlParts[0] == '') urlParts.shift();
    if( urlParts[urlParts.length - 1] == '') urlParts.pop();

    export default {
        components: {
            LoadingScreen,
            ComparisonBar,
            ProductListItem,
            NoProducts
        },
        computed: {
            ...mapState(
				['products', 'comparisons'],
            )
        },
        methods: {
            setDefaultFilters( filters ) {
                let vm = this;

                if( filters.fridge_colour != undefined ) {
                    filters.fridge_colour.forEach(function(colour) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'fridge_colour', 'product_type' : 'refrigeration', 'value' : colour } );
                    });
                }

                if( filters.fridge_features != undefined ) {
                    filters.fridge_features.forEach(function(feature) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'fridge_features', 'product_type' : 'refrigeration', 'value' : feature } );
                    });
                }

                if( filters.fridge_size != undefined ) {
                    filters.fridge_size.forEach(function(size) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'fridge_size', 'product_type' : 'refrigeration', 'value' : size } );
                    });
                }

                if( filters.fridge_type != undefined ) {
                    filters.fridge_type.forEach(function(type) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'fridge_type', 'product_type' : 'refrigeration', 'value' : type } );
                    });
                }
            },
            setFilterString() {
                let vm = this;

                var append = '?',
                    fridge_colours = [],
                    fridge_sizes = [],
                    fridge_types = [],
                    fridge_features = [];
                
                if( vm.$store.state.products.active_filters.refrigeration.colours.length > 0 ){

                    vm.$store.state.products.active_filters.refrigeration.colours.forEach(function(colour) {
                        fridge_colours.push(colour.slug);
                    });

                    fridge_colours = fridge_colours.join(',');

                    append = append + '&fridge_colour=' + fridge_colours;
                }

                if( vm.$store.state.products.active_filters.refrigeration.features.length > 0 ){

                    vm.$store.state.products.active_filters.refrigeration.features.forEach(function(feature) {
                        fridge_features.push(feature.slug);
                    });

                    fridge_features = fridge_features.join('+');

                    append = append + '&fridge_features=' + fridge_features;
                }

                if( vm.$store.state.products.active_filters.refrigeration.sizes.length > 0 ){

                    vm.$store.state.products.active_filters.refrigeration.sizes.forEach(function(size) {
                        fridge_sizes.push(size.slug);
                    });

                    fridge_sizes = fridge_sizes.join(',');

                    append = append + '&fridge_size=' + fridge_sizes;
                }

                if( vm.$store.state.products.active_filters.refrigeration.types.length > 0 ){

                    vm.$store.state.products.active_filters.refrigeration.types.forEach(function(type) {
                        fridge_types.push(type.slug);
                    });

                    fridge_types = fridge_types.join(',');

                    append = append + '&fridge_type=' + fridge_types;
                }

                append = append.replace("?&", "?");
                if( append == '?' ) append = '';

                return append;
            },
            resetProducts() {
                let vm = this;

                var append = '?',
                    fridge_colours = [],
                    fridge_sizes = [],
                    fridge_types = [],
                    fridge_features = [];

                vm.$store.dispatch('updateProductLoadingStatus', true );

                fridge_colours = vm.getUrlParameter('fridge_colour');
                fridge_sizes = vm.getUrlParameter('fridge_size');
                fridge_types = vm.getUrlParameter('fridge_type');
                fridge_features = vm.getUrlParameter('fridge_features');

                if(urlParts[2] == 'colour' && urlParts[3] !== ''){
                    fridge_colours = urlParts[3];
                } else if(urlParts[2] == 'size' && urlParts[3] !== ''){
                    fridge_sizes = urlParts[3];
                } else if(urlParts[2] == 'type' && urlParts[3] !== ''){
                    fridge_types = urlParts[3];
                } else if(urlParts[2] == 'features' && urlParts[3] !== ''){
                    fridge_features = urlParts[3];
                }

                if( fridge_colours != undefined ) {
                    append = append + '&fridge_colour=' + fridge_colours;
                }
                if( fridge_sizes != undefined ) {
                    append = append + '&fridge_size=' + fridge_sizes;
                }
                if( fridge_types != undefined ) {
                    append = append + '&fridge_type=' + fridge_types;
                }
                if( fridge_features != undefined ) {
                    append = append + '&fridge_features=' + fridge_features;
                }

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-refrigeration/' + append,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.setDefaultFilters( response.filters );
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            },
            equalizeHeights()
            {
                const selectorsToEqualize = ['.pli', '.product-image-wrapper'];

                if(jQuery(window).width() > 750){
                    selectorsToEqualize.forEach(selector => {
                        // Cache the highest
                        var highestElement = 0;
                        
                        // Select and loop the elements you want to equalise
                        jQuery(selector).each((index, element) => {
                            // If this box is higher than the cached highest then store it
                            if(jQuery(element).height() > highestElement) {
                                highestElement = jQuery(element).height(); 
                            }
                        });  
                                
                        // Set the height of all those children to whichever was highest 
                        jQuery(selector).height(highestElement);
                    });
                }

            },
        },
        watch: {
            '$store.state.products.current_products': {
                handler: function(currentProducts) {
                    updateViewItemListDataLayer(currentProducts, 'Refrigeration List');
                },
                deep: true
            }
        },
        mounted(){
            let vm = this;

            vm.resetProducts();

            setTimeout(() => {
                vm.equalizeHeights();
            }, 2000);
      

            Event.$on( 'update_products', ($event) => {

                vm.$store.dispatch('updateProductLoadingStatus', true );

                var queryURL = vm.setFilterString();
                var pushURL = '/' + urlParts[0] + '/' + urlParts[1] + '/' + queryURL;

                window.history.pushState('', '', pushURL);

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-refrigeration/' + queryURL,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            });

        },
        data() {
            return {
            }
        }
    }

</script>