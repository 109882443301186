import ProductComparisonPage from '../../components/Products/ComparisonPage';
import WhereToBuy from '../../components/WhereToBuy/Index';

const productComparisonPage = {
  element: '#product-comparison-page',
  components: {
    ProductComparisonPage,
    WhereToBuy
  }
}

export default productComparisonPage;