import HobProductFilter from '../../components/Products/Hob/Filter';
import HobProductList from '../../components/Products/Hob/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveHob = {
  element: '#archive-hob',
  components: {
    HobProductFilter,
    HobProductList,
    WhereToBuy
  }
}

export default archiveHob;