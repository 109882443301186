<template>
    <div class="online-retailers">
        <template v-for="(retailer, index) in retailers">
            <div class="online-retailer" :key="'online-' + index">
                <div class="online-retailer__meta">
                    <img :src="retailer.store_logo.url">
                </div>
                <a class="button button--green-full" :key="index" :href="retailer.store_url" :title="retailer.store_name" :data-text="$t('product.buy_now')" target="_blank" @click="handleBuyNowClick(retailer, product)">
                    <span>{{ $t('product.buy_now') }}</span>
                </a>
            </div>
        </template>
    </div> 
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import moment from 'moment';

    export default {
        props: ['retailers', 'product'],
        methods: {
            getProductLink: function( product_link ){
                let uri = window.location.search.substring(1);

                if( Cookies.get('utm_campaign') !== null && Cookies.get('utm_campaign') !== "") {
                    product_link = product_link + '&tag=' + Cookies.get('utm_campaign');
                }

                return product_link;   
            },
            handleBuyNowClick: function(retailer, product) {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        'event': 'buy_online_dealer',
                        'name': retailer.store_url,
                        'items': [{
                            'item_id': product.ID,
                            'item_name': product.name,
                            'item_category': product.category
                        }]
                    });
                }
            }
        }
    }
</script>