

<template>
    <div class="filter">
		<div class="filter-underlay"></div>
		<div class="filter-results">
			<p v-if="products.loading == false" class="txt-greyLL">{{ product_count }}</p>
			<p v-else class="txt-greyLL">{{ $t('product.loading') }}</p>
		</div>
		<div v-if="products.active_filters.oven.colours.length > 0 || products.active_filters.oven.features.length > 0" class="filter-active-filters">
			<a v-for="(colour, colour_index) in products.active_filters.oven.colours" :key="'colour_index-' + colour_index" class="product-filter" @click="toggleFilter( 'oven_colour', colour )">
				<span>{{ cleanText(colour.name) }}</span>
			</a>
			<a v-for="(feature, feature_index) in products.active_filters.oven.features" :key="'feature_index-' + feature_index" class="product-filter" @click="toggleFilter( 'oven_features', feature )">
				<span>{{ cleanText(feature.name) }}</span>
			</a>
		</div>
		<div class="filter-button">
			<p>{{ $t('product.filter') }}</p>
			<div class="filtercta">
				<simple-svg :filepath="theme_path + 'images/svg/filters.svg'" :width="'19px'" :height="'20px'" />
			</div>
		</div>
		<div class="filter-wrap">
			<a class="update-filter">{{ $t('product.update') }}</a>
			<div class="closefilter">
				<simple-svg :filepath="theme_path + 'images/svg/close.svg'" :width="'11.5px'" :height="'11.5px'"/>
			</div>			

			<div class="filter-inner filter-oven">
				<div v-if="colours && colours.length > 0" class="filt filt-colour">
					<p class="title">{{ $t('product.colour') }}</p>
					<ul class="colours">
						<li v-for="(colour, index) in colours" :key="index">
							<a class="product-filter-link" :data-values="colour.slug" :class="{ active : activeFilter( 'oven_colour', colour ) }" data-type="oven_colour" @click="toggleFilter( 'oven_colour', colour )">
								<span v-html="colour.name + ' &rsaquo;'"></span>
							</a>
						</li>
					</ul>
				</div>
				
				<div v-if="features && features.length > 0" class="filt filt-feat">
					<p class="title">{{ $t('product.features') }}</p>
					<ul class="features">
						<li v-for="(current_feature, index) in features" :key="index">
							<a v-if="current_feature.feature !== null" class="product-filter-link" :data-values="current_feature.feature.slug" :class="{ active : activeFilter( 'oven_features', current_feature.feature ) }" data-type="oven_features" @click="toggleFilter( 'oven_features', current_feature.feature )">
								<span v-html="current_feature.feature.name + ' &rsaquo;'"></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props: [ 'colours', 'features', 'sizes' ],
        computed: {
            ...mapState(
				['products'],
			)
		},
        data() {
            return {
            }
        }
    }
</script>