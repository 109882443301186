<template>
    <div class="search-special">
        <h2 v-html="search_box.box_title"></h2>
        <p v-html="search_box.box_text"></p>
        <a :href="search_box_link">{{ $t('search.click_here') }} &gt;</a>
    </div>
</template>

<script>

export default {
    props: [ 'search_box' ],
    computed: {
        search_box_link: function(){
            let vm = this;
            var searchBoxLink = vm.search_box.box_link;

            if( vm.search_box.external_link ) {
                searchBoxLink = vm.search_box.box_link_external;
            }

            return searchBoxLink;
        },
    },
    data () {
		return { }
    }
}
</script>