export const updateViewItemListDataLayer = (currentProducts, item_list_name) => {
    const items = currentProducts.map(product => ({
        item_id: product.content.ID,
        item_name: product.content.name,
        item_category: product.content.type
    }));

    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push({
            'event': 'view_item_list',
            'item_list_name': item_list_name,
            'items': items
        });
    }
}