import CookerProductFilter from '../../components/Products/Cooker/Filter';
import CookerProductList from '../../components/Products/Cooker/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveCooker = {
  element: '#archive-cooker',
  components: {
    CookerProductFilter,
    CookerProductList,
    WhereToBuy
  }
}

export default archiveCooker;