import DishwasherProductFilter from '../../components/Products/Dishwasher/Filter';
import DishwasherProductList from '../../components/Products/Dishwasher/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const archiveDishwasher = {
	element: '#archive-dishwasher',
	components: {
		DishwasherProductFilter,
		DishwasherProductList,
		WhereToBuy
	}
}

export default archiveDishwasher;