<template>
    <div class="local-retailers">
        <div class="local-retailers__loading" v-if="loading">
            <div class="lds-ripple"><div></div><div></div></div>
            <span>{{ $t('where_to_buy.retailers') }}</span>
        </div>
        <div class="local-retailers__results"  v-else>
            <GmapMap :center="{lat: user.lat, lng: user.lng}" :zoom="12" map-type-id="terrain" ref="localRetailers">
                <template v-for="(retailer, index) in retailers">
                    <gmap-custom-marker :class="'wtb-marker-parent wtb-marker-parent-' + index" :key="'retailerpin-' + index" :marker="{ lat : retailer.Latitude, lng : retailer.Longitude }">
                        <div :class="'wtb-marker wtb-marker-' + index">
                            <div class="info-window">
                                <img :src="retailer.RetailerLogoUrl" />
                                <span class="close" @click.exact="toggleWindow( index, retailer.Latitude, retailer.Longitude )">&times;</span>
                            </div>
                            <div class="wtb-pin" @click.exact="toggleWindow( index, retailer.Latitude, retailer.Longitude )">
                                <img src="/app/themes/hisense/assets/dist/images/wtb-map-icon.png" />
                                <span class="number" :class="( (index + 1) > 9 ) ? 'large' : ''">{{ index + 1 }}</span>
                            </div>
                        </div>
                    </gmap-custom-marker>
                </template>
                
            </GmapMap>
            <div class="local-retailers__list">
                <template v-for="(retailer, index) in retailers">
                    <div :class="'local-retailer local-retailer-'+ index" :key="'retailer-'+index">
                        <div class="row row-1">
                            <div class="local-retailer__meta" @click.exact="panTo( retailer.Latitude, retailer.Longitude )">
                                <div class="local-retailer__map-no">
                                    <span>{{ ( index + 1 ) }}</span>
                                </div>
                                <img :src="retailer.RetailerLogoUrl">
                            </div>
                            <div class="local-retailer__address">
                                <h4 v-if="retailer.LocationName !== null" >{{ retailer.LocationName }}</h4>
                                <p v-if="retailer.StreetLine1 !== null" >{{ retailer.StreetLine1 }}</p>
                                <a v-if="retailer.Phone !== null">{{ retailer.Phone }}</a>
                            </div>
                            <div class="local-retailer__availability">
                                <p v-if="retailer.Availability == 'Available'" class="local-retailer__stock in-stock" :title="$t('where_to_buy.stock_availability')">{{ $t('where_to_buy.in_stock') }}</p>
                                <p v-else class="local-retailer__stock out-of-stock" :title="$t('where_to_buy.stock_availability')">{{ $t('where_to_buy.call_store') }}</p>
                                <p v-if="hasPromotion(retailer.Promotions)" class="local-retailer__promotion" v-html="hasPromotion(retailer.Promotions)"></p>
                            </div>
                        </div>
                        <div class="row row-2">
                            <div class="local-retailer__links">
                                <a class="directions" :href="'https://www.google.com/maps/dir/?api=1&origin=' + user.lat + ',' + user.lng + '&destination=' + retailer.Latitude + ',' + retailer.Longitude + '&travelmode=driving'" target="_blank" rel="noopener"><span>{{ $t('where_to_buy.directions') }}</span></a>
                                <a class="online" v-if="retailer.ProductLink !== null" :href="retailer.ProductLink" target="_blank" rel="noopener"><span>{{ $t('where_to_buy.view_online') }}</span></a>
                                <a class="online" v-if="retailer.LocationUrl !== null && retailer.ProductLink == null" :href="retailer.LocationUrl" target="_blank" rel="noopener"><span>{{ $t('where_to_buy.view_online') }}</span></a>
                            </div>
                            <div class="local-retailer__opening-times">
                                <p v-if="retailer.Hours !== null" v-html="openingTimes(retailer.Hours)"></p>
                            </div>
                        </div>
                        <div class="row row-mobile">
                            <a class="online" v-if="retailer.ProductLink !== null" :href="retailer.ProductLink" target="_blank" rel="noopener"><span>{{ $t('where_to_buy.view_online') }}</span></a>
                            <a class="online" v-if="retailer.LocationUrl !== null && retailer.ProductLink == null" :href="retailer.LocationUrl" target="_blank" rel="noopener"><span>{{ $t('where_to_buy.view_online') }}</span></a>
                            <a class="directions" :href="'https://www.google.com/maps/dir/?api=1&origin=' + user.lat + ',' + user.lng + '&destination=' + retailer.Latitude + ',' + retailer.Longitude + '&travelmode=driving'" target="_blank" rel="noopener"><span>{{ $t('where_to_buy.directions') }}</span></a>
                            <a class="call" v-if="retailer.Phone !== null" :href="'tel:'+retailer.Phone"><span>{{ $t('where_to_buy.call_store') }}</span></a>
                        </div>
                    </div>
                </template>
            </div> 
            <p class="disclaimer">*{{ $t('where_to_buy.stock_availability') }}</p>
        </div>
    </div> 
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import moment from 'moment';

    import GmapCustomMarker from 'vue2-gmap-custom-marker';

    export default {
        props: ['loading', 'retailers', 'user'],
        components: {
            GmapCustomMarker
        },
        methods: {
            openingTimes: function( hours ){
                var openingTimes = hours.split(','),
                    now = new Date();

                var currentDay = now.getDay() == 7 ? 0 : now.getDay();

                return 'Open Today<br>' + openingTimes[currentDay].substring(2).replace(/(:[^:]*):/g,'$1 - ');;
            },
            hasPromotion: function( promotion ){

                if( promotion !== undefined && promotion[0] !== undefined && promotion[0].PromotionMessage !== undefined ) {

                    var startDate   = moment(promotion[0].StartDateTimeUtc).format("X"),
                        endDate     = moment(promotion[0].EndDateTimeUtc).format("X"),
                        currentDate = moment().format("X");

                    if( currentDate >= startDate && currentDate <= endDate ) {
                        return promotion[0].PromotionMessage;
                    } else {
                        return false;
                    }

                }

                return false;

            },
            panTo: function( lat, lng ){
                let vm = this;

                vm.$refs.localRetailers.$mapPromise.then((map) => {
                    map.panTo({lat: lat, lng: lng})
                })
            },
            toggleWindow: function( index, lat, lng ){

                let vm = this;

                vm.$refs.localRetailers.$mapPromise.then((map) => {
                    map.panTo({lat: lat, lng: lng})
                })

                var list = jQuery('.product-wtb__results.active .local-retailers__list');
                var listItem = jQuery('.product-wtb__results.active .local-retailer-'+index);

                if( jQuery('.wtb-marker-parent-' + index).hasClass('') ) {
                    jQuery('.wtb-marker-parent-' + index).removeClass('active');
                } else {
                    jQuery('.wtb-marker-parent.active').removeClass('active');
                    jQuery('.wtb-marker-parent-' + index).addClass('active');
                }

                jQuery('.wtb-marker:not(.wtb-marker-' + index + ') .info-window').fadeOut();
                jQuery('.wtb-marker-' + index + ' .info-window').fadeToggle();

                list.scrollTop(list.scrollTop() - list.offset().top + listItem.offset().top); 
            }
        }
    }
</script>