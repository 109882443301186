<template>
    <div>
        <comparison-bar v-if="comparisons.soundbar.length > 0" :product_type="'soundbars'" :compare_products="comparisons.soundbar"></comparison-bar>
        <div class="wrapper">
            <loading-screen v-if="products.loading == true"></loading-screen>
            <product-list-item v-else v-for="product in products.current_products" :product="product" :key="product.id"></product-list-item>
            <no-products v-if="products.current_products == undefined && products.loading == false"></no-products>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import LoadingScreen from './../LoadingScreen';
    import ComparisonBar from './../ComparisonBar';
    import ProductListItem from './../ListItem';
    import NoProducts from './../NoProducts';
    import {updateViewItemListDataLayer} from '../../../helpers/datalayer';

    var urlParts = window.location.pathname.split('/');
    if( urlParts[0] == '') urlParts.shift();
    if( urlParts[urlParts.length - 1] == '') urlParts.pop();

    export default {
        components: {
            LoadingScreen,
            ComparisonBar,
            ProductListItem,
            NoProducts
        },
        computed: {
            ...mapState(
				['products', 'comparisons'],
            )
        },
        methods: {
            setDefaultFilters( filters ) {
                let vm = this;

                if( filters.soundbar_features != undefined ) {
                    filters.soundbar_features.forEach(function(feature) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'soundbar_features', 'product_type' : 'soundbar', 'value' : feature } );
                    });
                }

            },
            setFilterString() {
                let vm = this;

                var append = '?',
                    soundbar_features = [];

                if( vm.$store.state.products.active_filters.soundbar.features.length > 0 ){

                    vm.$store.state.products.active_filters.soundbar.features.forEach(function(feature) {
                        soundbar_features.push(feature.slug);
                    });

                    soundbar_features = soundbar_features.join('+');

                    append = append + '&soundbar_features=' + soundbar_features;

                }

                append = append.replace("?&", "?");
                if( append == '?' ) append = '';

                return append;
            },
            resetProducts() {
                let vm = this;

                var append = '?',
                    soundbar_features = [];

                vm.$store.dispatch('updateProductLoadingStatus', true );

                soundbar_features = vm.getUrlParameter('soundbar_features');

                if(urlParts[2] == 'features' && urlParts[3] !== ''){
                    soundbar_features = urlParts[3];
                }

                if( soundbar_features != undefined ) {
                    append = append + '&soundbar_features=' + soundbar_features;
                }

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-soundbar/' + append,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.setDefaultFilters( response.filters );
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            }
        },
        watch: {
            '$store.state.products.current_products': {
                handler: function(currentProducts) {
                    updateViewItemListDataLayer(currentProducts, 'Soundbar List');
                },
                deep: true
            }
        },
        mounted(){
            let vm = this;

            vm.resetProducts();

            Event.$on( 'update_products', ($event) => {

                vm.$store.dispatch('updateProductLoadingStatus', true );

                var queryURL = vm.setFilterString();
                var pushURL = '/' + urlParts[0] + '/' + urlParts[1] + '/' + queryURL;

                window.history.pushState('', '', pushURL);

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-soundbar/' + queryURL,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            });

        },
        data() {
            return {
            }
        }
    }

</script>