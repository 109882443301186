<template>
    <div>
        <comparison-bar v-if="comparisons.laundry.length > 0" :product_type="'laundry'" :compare_products="comparisons.laundry"></comparison-bar>
        <div class="wrapper">
            <loading-screen v-if="products.loading == true"></loading-screen>
            <product-list-item v-else v-for="product in products.current_products" :product="product" :key="product.id"></product-list-item>
            <no-products v-if="products.current_products == undefined && products.loading == false"></no-products>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import LoadingScreen from './../LoadingScreen';
    import ComparisonBar from './../ComparisonBar';
    import ProductListItem from './../ListItem';
    import NoProducts from './../NoProducts';
    import {updateViewItemListDataLayer} from '../../../helpers/datalayer';

    var urlParts = window.location.pathname.split('/');
    if( urlParts[0] == '') urlParts.shift();
    if( urlParts[urlParts.length - 1] == '') urlParts.pop();

    export default {
        components: {
            LoadingScreen,
            ComparisonBar,
            ProductListItem,
            NoProducts
        },
        computed: {
            ...mapState(
				['products', 'comparisons'],
            )
        },
        methods: {
            setDefaultFilters( filters ) {
                let vm = this;

                if( filters.laundry_colour != undefined ) {
                    filters.laundry_colour.forEach(function(colour) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'laundry_colour', 'product_type' : 'laundry', 'value' : colour } );
                    });
                }

                if( filters.laundry_features != undefined ) {
                    filters.laundry_features.forEach(function(feature) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'laundry_features', 'product_type' : 'laundry', 'value' : feature } );
                    });
                }

                if( filters.laundry_type != undefined ) {
                    filters.laundry_type.forEach(function(type) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'laundry_type', 'product_type' : 'laundry', 'value' : type } );
                    });
                }

                if( filters.laundry_size != undefined ) {
                    filters.laundry_size.forEach(function(size) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'laundry_size', 'product_type' : 'laundry', 'value' : size } );
                    });
                }

            },
            setFilterString() {

                let vm = this;

                var append = '?',
                    laundry_colour = [],
                    laundry_features = [],
                    laundry_types = [],
                    laundry_size = [];

                if( vm.$store.state.products.active_filters.laundry.colours.length > 0 ){

                    vm.$store.state.products.active_filters.laundry.colours.forEach(function(colour) {
                        laundry_colour.push(colour.slug);
                    });

                    laundry_colour = laundry_colour.join(',');

                    append = append + '&laundry_colour=' + laundry_colour;
                }
                if( vm.$store.state.products.active_filters.laundry.features.length > 0 ){

                    vm.$store.state.products.active_filters.laundry.features.forEach(function(feature) {
                        laundry_features.push(feature.slug);
                    });

                    laundry_features = laundry_features.join('+');

                    append = append + '&laundry_features=' + laundry_features;
                }
                if( vm.$store.state.products.active_filters.laundry.sizes.length > 0 ){

                    vm.$store.state.products.active_filters.laundry.sizes.forEach(function(size) {
                        laundry_size.push(size.slug);
                    });

                    laundry_size = laundry_size.join(',');

                    append = append + '&laundry_size=' + laundry_size;
                }

                if( vm.$store.state.products.active_filters.laundry.types.length > 0 ){

                    vm.$store.state.products.active_filters.laundry.types.forEach(function(type) {
                        laundry_types.push(type.slug);
                    });

                    laundry_types = laundry_types.join(',');

                    append = append + '&laundry_type=' + laundry_types;
                }

                append = append.replace("?&", "?");
                if( append == '?' ) append = '';

                return append;
            },
            resetProducts() {
                let vm = this; 

                var append = '?',
                    laundry_colour = [],
                    laundry_features = [],
                    laundry_types = [],
                    laundry_size = [];

                vm.$store.dispatch('updateProductLoadingStatus', true );

                laundry_colour = vm.getUrlParameter('laundry_colour');
                laundry_features = vm.getUrlParameter('laundry_features');
                laundry_size = vm.getUrlParameter('laundry_size');
                laundry_types = vm.getUrlParameter('laundry_type');

                if(urlParts[2] == 'colour' && urlParts[3] !== ''){
                    laundry_colour = urlParts[3];
                } else if(urlParts[2] == 'features' && urlParts[3] !== ''){
                    laundry_features = urlParts[3];
                } else if(urlParts[2] == 'size' && urlParts[3] !== ''){
                    laundry_size = urlParts[3];
                } else if(urlParts[2] == 'types' && urlParts[3] !== ''){
                    laundry_types = urlParts[3];
                }

                if( laundry_features != undefined ) {
                    append = append + '&laundry_features=' + laundry_features;
                }
                if( laundry_colour != undefined ) {
                    append = append + '&laundry_colour=' + laundry_colour;
                }
                if( laundry_size != undefined ) {
                    append = append + '&laundry_size=' + laundry_size;
                }
                if( laundry_types != undefined ) {
                    append = append + '&laundry_type=' + laundry_types;
                }

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-laundry/' + append,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){

                        vm.setDefaultFilters( response.filters );

                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );

                    }
                });

            }
        },
        watch: {
            '$store.state.products.current_products': {
                handler: function(currentProducts) {
                    updateViewItemListDataLayer(currentProducts, 'Laundry List');
                },
                deep: true
            }
        },
        mounted(){
            let vm = this;

            vm.resetProducts();

            Event.$on( 'update_products', ($event) => {

                vm.$store.dispatch('updateProductLoadingStatus', true );

                var queryURL = vm.setFilterString();
                var pushURL = '/' + urlParts[0] + '/' + urlParts[1] + '/' + queryURL;

                window.history.pushState('', '', pushURL);

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-laundry/' + queryURL,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            });

        },
        data() {
            return {
            }
        }
    }

</script>