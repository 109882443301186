import WhereToBuy from '../../components/WhereToBuy/Index';
import WhereToBuyButton from '../../components/WhereToBuy/Button';

const compareDishwashersBlock = {
  element: '.comparison-products-block--dishwashers',
  components: {
    WhereToBuyButton,
    WhereToBuy
  }
}

export default compareDishwashersBlock;