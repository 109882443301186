<template>
    <div class="comparison-bar">
        <div class="comparison-products">
            <div v-for="product in compare_products" :key="product.id" class="comparison-product">
                <div class="product-image" v-html="product.images.thumbnail"></div>
                <a class="remove-product" @click="toggleCompare( product_type, product )">&times;</a>
            </div>
        </div>
        <a class="compare-button" :href="compare_link">{{ $t('product.compare') }}<span> {{ $t('product.products') }}</span></a>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props: [
            'compare_products',
            'product_type',
        ],
        computed: {
            ...mapState(
				['products'],
            ),
            compare_link: function(){
                let vm = this;
                return '/' + vm.product_type + '/compare-products/';
            },
        },
        methods: {
            toggleCompare: function ( product_type, product ) {
                let vm = this;
                vm.$store.dispatch( 'updateProductCompare', { 'product_type' : product_type, 'product' : product } );
            }
        },
        data() {
            return {
            }
        }
    }
</script>