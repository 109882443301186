import ProductLister from '../../components/FlexibleContent/ProductLister/List';
import WhereToBuy from '../../components/WhereToBuy/Index';

const productListerPage = {
  element: '#product-lister',
  components: {
    ProductLister,
    WhereToBuy
  }
}

export default productListerPage;