<template>
    <div>
        <div class="found-count">
            <p v-html="found_count"></p>
        </div>
        <div class="wrapper">
            <single-result v-for="product in results" :product="product" :key="product.id"></single-result>
        </div>
    </div>
</template>
<script>
    import SingleResult from './Single';

    export default {
        props: [ 'results' ],
        components: {
            SingleResult
        },
        computed: {
            found_count: function(){
                let vm = this;
                return vm.$t('product.products') + ' <span>(' + vm.results.length + ')</span>';
            }
        },
        data() {
            return { }
        }
    }
</script>