<template>
    <div>
        <comparison-bar v-if="comparisons.tv.length > 0" :product_type="'tvs'" :compare_products="comparisons.tv"></comparison-bar>
        <div class="wrapper">
            <loading-screen v-if="products.loading == true"></loading-screen>
            <product-list-item v-else v-for="product in products.current_products" :product="product" :key="product.id"></product-list-item>
            <no-products v-if="products.current_products == undefined && products.loading == false"></no-products>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import LoadingScreen from './../LoadingScreen';
    import ComparisonBar from './../ComparisonBar';
    import ProductListItem from './../ListItem';
    import NoProducts from './../NoProducts';
    import {updateViewItemListDataLayer} from '../../../helpers/datalayer';

    var urlParts = window.location.pathname.split('/');
    if( urlParts[0] == '') urlParts.shift();
    if( urlParts[urlParts.length - 1] == '') urlParts.pop();

    export default {
        components: {
            LoadingScreen,
            ComparisonBar,
            ProductListItem,
            NoProducts
        },
        computed: {
            ...mapState(
				['products', 'comparisons'],
            )
        },
        methods: {
            setDefaultFilters( filters ) {
                let vm = this;

                if( filters.tv_features != undefined ) {
                    filters.tv_features.forEach(function(feature) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'tv_features', 'product_type' : 'tv', 'value' : feature } );
                    });
                } 
                
                if( filters.tv_sizes != undefined ) {
                    filters.tv_sizes.forEach(function(size) {
                        vm.$store.dispatch( 'updateProductFilter', { 'name' : 'tv_sizes', 'product_type' : 'tv', 'value' : size } );
                    });
                }

            },
            setFilterString() {
                let vm = this;

                var append = '?',
                    tv_features = [],
                    tv_sizes = [];
                
                if( vm.$store.state.products.active_filters.tv.features.length > 0 ){

                    vm.$store.state.products.active_filters.tv.features.forEach(function(feature) {
                        tv_features.push(feature.slug);
                    });

                    tv_features = tv_features.join('+');

                    append = append + '&tv_features=' + tv_features;

                } 
                
                if( vm.$store.state.products.active_filters.tv.sizes.length > 0 ){

                    vm.$store.state.products.active_filters.tv.sizes.forEach(function(size) {
                        tv_sizes.push(size.values.join(','));
                    });

                    tv_sizes = tv_sizes.join(',');

                    append = append + '&tv_sizes=' + tv_sizes;

                }

                append = append.replace("?&", "?");
                if( append == '?' ) append = '';

                return append;
            },
            resetProducts() {
                let vm = this;

                var append = '?',
                    tv_features = [],
                    tv_sizes = [];

                vm.$store.dispatch('updateProductLoadingStatus', true );

                tv_features = vm.getUrlParameter('tv_features');
                tv_sizes = vm.getUrlParameter('tv_sizes');
                
                if(urlParts[2] == 'features' && urlParts[3] !== ''){
                    tv_features = urlParts[3];
                } else if(urlParts[2] == 'sizes' && urlParts[3] !== ''){

                    if( jQuery('#sizes-'+urlParts[3]).data('values') !== '' ) {
                        tv_sizes = jQuery('#sizes-'+urlParts[3]).data('values')
                    }
                }

                if( tv_features != undefined ) {
                    append = append + '&tv_features=' + tv_features;
                }
                if( tv_sizes != undefined ) {
                    append = append + '&tv_sizes=' + tv_sizes;
                }

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-tv/' + append,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.setDefaultFilters( response.filters );
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );
                    }
                });

            },
            equalizeHeights()
            {
                const selectorsToEqualize = ['.pli', '.product-image-wrapper'];

                if(jQuery(window).width() > 750){
                    selectorsToEqualize.forEach(selector => {
                        // Cache the highest
                        var highestElement = 0;
                        
                        // Select and loop the elements you want to equalise
                        jQuery(selector).each((index, element) => {
                            // If this box is higher than the cached highest then store it
                            if(jQuery(element).height() > highestElement) {
                                highestElement = jQuery(element).height(); 
                            }
                        });  
                                
                        // Set the height of all those children to whichever was highest 
                        jQuery(selector).height(highestElement);
                    });
                }

            },
        },
        watch: {
            '$store.state.products.current_products': {
                handler: function(currentProducts) {
                    updateViewItemListDataLayer(currentProducts, 'TV List');
                },
                deep: true
            }
        },
        mounted(){
            let vm = this;

            vm.resetProducts();

            setTimeout(() => {
                vm.equalizeHeights();
            }, 2000);
      
            Event.$on( 'update_products', ($event) => {
                vm.$store.dispatch('updateProductLoadingStatus', true );

                var queryURL = vm.setFilterString();
                var pushURL = '/' + urlParts[0] + '/' + urlParts[1] + '/' + queryURL;

                window.history.pushState('', '', pushURL);

                jQuery.ajax({
                    url: '/wp-json/hisense/v1/all-tv/' + queryURL,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        vm.$store.dispatch('updateProducts', response.products );
                        vm.$store.dispatch('updateProductLoadingStatus', false );

                        console.log('Ciaran');
                    }
                });
            });

        },
        data() {
            return {
            }
        }
    }

</script>