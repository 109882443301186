(function ($) {
    
    var cursor = {
        delay: 4,
        _x: 0,
        _y: 0,
        endX: (window.innerWidth / 2),
        endY: (window.innerHeight / 2),
        cursorVisible: false,
        $dot: $('.cursor-takeover'),
    
        init: function () {
            // Set up element sizes
            this.dotSize = this.$dot.offsetWidth;
    
            this.setupEventListeners();
        },
    
        setupEventListeners: function () { 
            var self = this;

            $('.swiper-zoom-container.has-zoom').on('mouseover', function(e){
                self.toggleCursorVisibility(true);
                self.toggleCursor('zoom');
            }).on('mouseout', function(){
                self.toggleCursorVisibility(false);
                self.toggleCursor('zoom');
            });

            document.addEventListener('mousemove', function (e) {
                self.endX = e.pageX;
                self.endY = e.pageY;

                self.$dot.css( { "top" : self.endY + 'px', "left" : self.endX + 'px' } );
            });
    
        },
    
        toggleCursor: function( type = '' ) {
            var self = this;
    
            if( type == 'zoom' ) {
                if (self.cursorVisible) {
                    self.$dot.addClass('cursor-takeover__visible cursor-takeover__zoom');
                } else {
                    self.$dot.removeClass('cursor-takeover__visible cursor-takeover__zoom');
                }
            }
    
        },
    
        toggleCursorSize: function ( active = false ) {

            var self = this;

            if( active ) {
                self.$dot.addClass('cursor-takeover__active');
            } else {
                self.$dot.removeClass('cursor-takeover__active');
            }

        },
    
        toggleCursorVisibility: function ( visibility = false ) {
            var self = this;

            self.cursorVisible = visibility;
    
            if (self.cursorVisible) {
                self.$dot.fadeTo( 0, 1 );
            } else {
                self.$dot.fadeTo( 0, 0 );
            }
        }
    }

    cursor.init();

})(jQuery);