<template>
    <div>
        <search-results-header :results="current_search_results" :term="search_term" :is_searching="is_searching"></search-results-header>
        <div class="lds-ripple" v-if="is_searching"><div></div><div></div></div>
        <product-search-results v-if="current_search_results.products.length > 0" :results="current_search_results.products"></product-search-results>
        <post-search-results v-if="current_search_results.posts.length > 0" :results="current_search_results.posts"></post-search-results>
        <no-results v-if=" !is_searching && current_search_results.products.length == 0 && current_search_results.posts.length == 0" :no_search_results_boxes="current_search_results.no_search_results_boxes"></no-results>
    </div>
</template>

<script>

import SearchResultsHeader from './Header';
import ProductSearchResults from './Products/Index';
import PostSearchResults from './Posts/Index';
import NoResults from './NoResults';

export default {
    components: {
        SearchResultsHeader,
        ProductSearchResults,
        PostSearchResults,
        NoResults
    },
    created() {
        let vm = this;
        vm.search_term = vm.getUrlParameter('s');
        vm.search_term = vm.search_term.replace( '+', '%20' );

        jQuery.ajax({
            url: '/wp-json/hisense/v1/search-result/' + vm.search_term,
            method: 'GET',
            success: function( response, textStatus, xhr ){
                if( response.products !== undefined ) {
                    vm.current_search_results.products = response.products;
                }
                if( response.posts !== undefined ) {
                    vm.current_search_results.posts = response.posts;
                }
                if( response.search_boxes !== undefined ) {
                    vm.current_search_results.search_boxes = response.search_boxes;
                }
                if( response.no_search_results_boxes !== undefined ) {
                    vm.current_search_results.no_search_results_boxes = response.no_search_results_boxes;
                }
                vm.is_searching = false;
            }
        });
    },
    mounted() {
        var maxHeight = Math.max.apply(null, jQuery('.product-image').map(function () {
            return jQuery(this).outerHeight();
        }).get());

        jQuery('.product-info .product-image').css({ 'min-height' : maxHeight });
    },
    data () {
		return {
            is_searching: true,
            search_term: null,
            current_search_results: {
                products: [],
                posts: [],
                search_boxes: [],
                no_search_results_boxes: []
            }
        }
    }
}
</script>