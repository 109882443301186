<template>
    <button :class="[ buttonClass, { loading : wtb_loading }]" :data-text="wtb_button_text" @click.stop.prevent="fetchLocation( product_id )"><span>{{ wtb_button_text }}</span></button>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { TimelineMax, Power3 } from "gsap";

    export default {
        props: [
            'product_id', 'additional_class'
        ],
        computed: {
            ...mapState(
                ['where_to_buy'],
            ),
            buttonClass() {
                let vm = this;

                if( vm.additional_class ) {
                    return 'button button--green-full ' + vm.additional_class + ' js-button-wtb js-button-wtb-' + vm.product_id;
                }

                return 'button button--green-full js-button-wtb js-button-wtb-' + vm.product_id;
            }
        },
        mounted(){
            let vm = this;
            vm.current_product = vm.product;

            vm.wtb_button_text = vm.$t('product.buy_now');

            Event.$on( 'reset_wtb', ($event) => {
                vm.wtb_loading = false;
                vm.wtb_button_text = vm.$t('product.buy_now')
            });
        },
        methods: {
            fetchLocation: function( product_id ){
                let vm = this,
                    store = this.$store,
                    api_url = '/wp-json/hisense/v1/where-to-buy/' + product_id;

                vm.wtb_loading = true;
                vm.wtb_button_text = vm.$t('search.searching');
                
                jQuery('.pullout-overlay-wtb').addClass('loading');

                jQuery('.pullout-overlay-wtb').show().animate({
                    opacity: 1,
                }, 300, function() {
                });

                // var pulloutTimelineWtb = new TimelineMax({ paused: true, reversed: true });

                //     pulloutTimelineWtb
                //         .from( jQuery('.product-wtb'), 0, { css: { right: "-110%" }, ease: Power3.easeInOut })
                //         .from( jQuery('.pullout-overlay-wtb'), 0, { css: { opacity: "0" }, ease: Power3.easeInOut })
                //         .to( jQuery('.product-wtb'), 0.3, { css: { right: "0%" }, ease: Power3.easeInOut }, 0.3 )
                //         .to( jQuery('.pullout-overlay-wtb'), 0.3, { opacity: 1, ease: Power3.easeInOut, onComplete: function() { jQuery('.pullout-overlay-wtb').hide(); } } )
                //         .set( jQuery('.pullout-overlay-wtb').addClass('active') ).play();

                jQuery('html').addClass('noscroll');

                Event.$emit('open_wtb');

                if( vm.where_to_buy.user !== undefined && vm.where_to_buy.user.lat !== undefined  && vm.where_to_buy.user.lng !== undefined ) {
                    api_url = api_url + '?lat=' + vm.where_to_buy.user.lat + '&lng=' + vm.where_to_buy.user.lng;
                }
                vm.fetchProductWTB( api_url );

            },
            fetchProductWTB: function( api_url ){
                let vm = this,
                    store = this.$store;

                jQuery('.product-wtb').css({ right: "-110%"});

                jQuery.ajax({
                    url: api_url,
                    method: 'GET',
                    success: function( response, textStatus, xhr ){
                        store.dispatch( 'updateWheretoBuy', {
                            product: response
                        } );

                        vm.wtb_loading = false;
                        vm.wtb_button_text = vm.$t('product.buy_now');

                        jQuery('.pullout-overlay-wtb').removeClass('loading');

                        jQuery('.product-wtb').animate({
                                right: 0,
                            }, 300, function() {
                        });

                    },
                    error: function (request, status, error) {
                    }
                });

            }
        },
        data() {
            return {
                userLocation: null,
                wtb_loading : false,
                wtb_button_text: 'Buy Now'
            }
        }
    }
</script>