<template>
    <div class="search-header">
        <div class="search-summary" v-if="is_searching">
            <h1>{{ $t('search.search_for') }}</h1>
            <p class="search-term" v-html="search_term"></p>
        </div>
        <div v-else class="search-summary">
            <h1>{{ $t('search.search_results_for') }}</h1>
            <p class="search-term" v-html="search_term"></p>
            <p class="result-count" v-html="results_count"></p>
        </div>
        <search-boxes v-for="(search_box, sb_index) in results.search_boxes" :search_box="search_box" :key="sb_index"></search-boxes>
    </div>
</template>

<script>
    import SearchBoxes from './SearchBoxes';

    export default {
        props: [ 'is_searching', 'results', 'term' ],
        components: {
            SearchBoxes
        },
        computed: {
            search_term: function(){
                let vm = this;
                var searchTerm = vm.term;

                if( searchTerm !== undefined ) {
                    searchTerm = searchTerm.replace( '%20', ' ' );
                }

                return searchTerm;
            },
            results_count: function(){
                let vm = this;
                var totalCount = vm.results.products.length + vm.results.posts.length;
                var resultsCount = ( totalCount == 1 ) ? totalCount + ' ' + vm.$t('search.result') : totalCount + ' ' + vm.$t('search.results'); 

                return resultsCount;
            }
        },
        data() {
            return { }
        }
    }
</script>