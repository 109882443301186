<template>
    <div class="no-products">
        <p>{{ $t('product.no_products') }}</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        }
    }
</script>